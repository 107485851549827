import React, { useEffect, useState } from 'react'
import { Button, message, Result } from 'antd';
import { useSearchParams } from 'react-router-dom';
import { getPartnerDataByAccountId, updateStripeAccountStatus } from '../../script/auth';

export default function ReturnPage() {
  const [searchParams, setSearchParams] = useSearchParams();
  const [accountData, setAccountData] = useState(null);

  useEffect(() => {
    try {
      const account = atob(searchParams.get("account"));
      getPartnerDataByAccountId({ id: account })
        .then((response) => {
          if (response.length > 0) {
            updateStripeAccountStatus({ id: response[0].id })
              .then((response) => {
                console.log(response);
              })
              .catch((err) => {
                message.error("Something went wrong while updating your account status, please contact us to fix this issue!");
              })
          }
        })
        .catch((err) => {
          console.log(err);
        })
    }
    catch (err) {
      console.log(err);
    }
  }, [])


  return (
    <Result
      status="success"
      title="Thank you!"
      subTitle=""
    />
  )
}
