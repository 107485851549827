import { PlusOutlined, SendOutlined } from '@ant-design/icons';
import { Button, Col, DatePicker, Input, message, Modal, Row, Select, Table } from 'antd';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { sendInvoiceToCustomer } from '../../api/point';
import { ClientSideBar } from '../../container/ClientSideBar';
import { addInvoiceData, getClientDataByID, getClientInvoiceData, getPartnerData } from '../../script/auth';

export default function ClientBilling() {

  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [clientData, setClientData] = useState(null);
  const [createInvoiceModal, setCreateInvoiceModal] = useState(false);
  const [clientInvoice, setClientInvoice] = useState([]);
  const [emailList, setEmailList] = useState([]);
  const [invoiceDate, setInvoiceDate] = useState(null);
  const [paymentDueDate, setPaymentDueDate] = useState(null);
  const [invoiceLoading, setInvoiceLoading] = useState(false);
  const [partnerData, setPartnerData] = useState([]);
  const [invoicePartner, setInvoicePartner] = useState(null);
  const [itemList, setItemList] = useState([{ name: "", price: null }]);



  const invoiceColumn = [
    {
      title: 'Due',
      key: "dueDate",
      dataIndex: "dueDate",
    },
    {
      title: 'Date',
      key: "invoiceDate",
      dataIndex: "invoiceDate",
    },
    {
      title: 'Amount',
      key: "totalPrice",
      dataIndex: "totalPrice",
    },
    {
      title: 'Status',
      key: "status",
      dataIndex: "status",
      render: (e) => e == 0 ? <span>Pending</span> : <span>Paid</span>
    }
  ]

  const init = async () => {
    const data = await localStorage.getItem("user");
    try {
      if (data) {
        const user = JSON.parse(data);
        if (user.role == 0) {
          navigate("/client-login");
        } else {
          getClientDataByID({ id: user.client.id })
            .then((response) => {
              setClientData(response);
              setLoading(false);
            })
            .catch((err) => {
              console.log(err);
              setLoading(false);
              message.error("Something went wrong while fetching client data, please try again later!");
            })

          getClientInvoiceData({ client: user.client.id })
            .then((response) => {
              setClientInvoice(response);
            })
            .catch((err) => {
              console.log(err);
              message.error("Something went wrong while fetching client invoice data, please try again later!");
            })


          getPartnerData()
            .then((response) => {
              setPartnerData(response);
            })
            .catch((err) => {
              console.log(err);
              message.error("Something went wrong while fetching partner invoice data, please try again later!");
            })
        }
      }
    }
    catch (err) {
      console.log(err)
      navigate("/client-login");
    }
  }

  useEffect(() => {
    if (localStorage.getItem('user')) {
      const user = JSON.parse(localStorage.getItem("user"));
      if (user.role == 0) {
        navigate("/client-login");
      } else {
        init();
      }
    }
    else navigate("/client-login");
  }, [])


  const handleNewEmailChange = (e, key) => {
    e.preventDefault();

    setEmailList(s => {
      const newArr = s.slice();
      newArr[key].value = e.target.value;
      return newArr;
    });
  }

  const handleNewItemChange = (e, field, key) => {
    e.preventDefault();

    if (field == "name") {
      setItemList(s => {
        const newArr = s.slice();
        newArr[key].name = e.target.value;
        return newArr;
      });
    } else {
      setItemList(s => {
        const newArr = s.slice();
        newArr[key].price = e.target.value;
        return newArr;
      });
    }
  }

  const validateEmail = (email) => {
    return String(email)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );
  };

  const sendInvoice = async () => {
    var email = [clientData?.email];
    emailList.map((list) => {
      if (validateEmail(list.value)) email.push(list.value)
      else { message.error("Please add correct email address!"); return; }
    })

    var item = [];
    var totalPrice = 0;
    itemList.map((list) => {
      if (list.name == "" || list.price == "") { message.error("Please complete item list!"); return; }
      else {
        item.push({ name: list.name, value: "$" + parseFloat(list.price).toFixed(2) });
        totalPrice += parseFloat(list.price);
      }
    })

    setInvoiceLoading(true);

    await Promise.all(email.map(async (child) => {

      var invoiceData = {
        email: child,
        item: item,
        invoiceDate: invoiceDate,
        dueDate: paymentDueDate,
        totalPrice: totalPrice,
        partner: invoicePartner,
        status: 0
      }

      await sendInvoiceToCustomer(invoiceData)
        .then(async (response) => {
          if (response.data.status == "success") {
            invoiceData.client = clientData.id;
            invoiceData.invoice = response.data.response.id;
            await addInvoiceData(invoiceData);
          } else {
            console.log(response.data);
            message.error("Something went wrong while sending invoice to user, please try again later!");
          }
        })
        .catch((err) => {
          console.log(err);
          message.error("Something went wrong while sending invoice to user, please try again later!");
        })
    }))
    setInvoiceLoading(false);
    setCreateInvoiceModal(false);
    init();
  }

  return (
    <ClientSideBar>
      <Row>
        {/* <Col span={24} className="create-invoice-container">
                    <Button type="primary" onClick={() => setCreateInvoiceModal(true)}>Create New Invoice</Button>
                </Col> */}
        <Col span={24}>
          <Table
            loading={loading}
            columns={invoiceColumn}
            dataSource={clientInvoice}
          />
        </Col>
      </Row>


      <Modal
        title="Create Invoice"
        open={createInvoiceModal}
        onCancel={() => setCreateInvoiceModal(false)}
        footer={false}
        width="800px"
      >
        <Row>
          <Col span={24}>
            <Row gutter={[12, 0]}>
              <Col span={24}>
                <div className='email-title-view'>
                  <h4>Email</h4>
                  <Button
                    type='primary'
                    size='small'
                    onClick={() => {
                      setEmailList(origin => {
                        return [
                          ...origin, {
                            type: "text",
                            value: ""
                          }
                        ]
                      })
                    }}
                    className='add-new-button'
                  >
                    Add
                  </Button>
                </div>
                <span>{clientData?.email}</span>
                {
                  emailList.map((child, key) => (
                    <Row className='new-email-input' key={key}>
                      <Col span={8}>
                        <Input type={child.type} value={child.value} placeholder="Enter email address" onChange={(e) => handleNewEmailChange(e, key)} />
                      </Col>
                    </Row>
                  ))
                }
              </Col>

              <Col span={24} className="invoice-date-container">
                <span className='date-label'>Inovice Date</span>
                <DatePicker onChange={(date, dateString) => setInvoiceDate(dateString)} />
              </Col>

              <Col span={24} className="invoice-date-container">
                <span className='date-label'>Payment Due</span>
                <DatePicker onChange={(date, dateString) => setPaymentDueDate(dateString)} />
              </Col>

            </Row>
            <Row gutter={12}>
              <Col span={8}>
                <span>Item</span>
              </Col>

              <Col span={6}>
                <span>Price</span>
              </Col>
            </Row>

            {
              itemList.map((child, key) => (
                <Row gutter={12} key={key} className='new-email-input'>
                  <Col span={8}>
                    <Input
                      placeholder='Item'
                      value={child.name}
                      onChange={(e) => handleNewItemChange(e, "name", key)}
                    />
                  </Col>

                  <Col span={6}>
                    <Input
                      placeholder='Price'
                      value={child.price}
                      type="number"
                      onChange={(e) => handleNewItemChange(e, "price", key)}
                      prefix={"$"}
                    />
                  </Col>
                </Row>
              ))
            }

            <Row className='new-email-input'>
              <Col span="8">
                <Button
                  type="primary"
                  icon={<PlusOutlined />}
                  onClick={() => {
                    setItemList(origin => {
                      return [
                        ...origin, {
                          name: "",
                          price: null
                        }
                      ]
                    })
                  }}
                >
                  Add
                </Button>
              </Col>
            </Row>
            <Row>
              <Col span={24} className="partner-account-container">
                <span>Partner Account(Optional)</span><br />
                <Select
                  defaultValue="none"
                  style={{
                    width: 200,
                  }}
                  onChange={(e) => setInvoicePartner(e)}
                  options={partnerData.map((partner) => ({
                    label: partner.name,
                    value: partner.id
                  }))}
                />
              </Col>
            </Row>

            <Row>
              <Col span={24} className="invoice-send-button-container">
                <Button type="primary" icon={<SendOutlined />} onClick={() => sendInvoice()} size={"middle"} loading={invoiceLoading}>
                  Send
                </Button>
              </Col>
            </Row>
          </Col>
        </Row>
      </Modal>
    </ClientSideBar>
  )
}
