import React, { useEffect, useState } from 'react'
import { Sidebar } from '../../container/Sidebar'
import { useLocation, useNavigate } from 'react-router-dom';
import { ClientSideBar } from '../../container/ClientSideBar';
import { Button, Col, Form, Input, Modal, Popconfirm, Progress, Row, Table, message } from 'antd';
import { addDocumentData, getDocumentByParent, removeDocumentData, updateDocumentInfo, updateDocumentStatus, updateVideoInfo } from '../../script/document';
import { fileUploadToStroage } from '../../script/file';
import { convertAndUpdateDocumentAi, convertDocumentAi, sendTestNotification } from '../../api/point';
import { sendContentNotification  } from '../../script/auth';
import TextArea from 'antd/es/input/TextArea';

export default function DocumentDetail() {

  const location = useLocation();
  const navigate = useNavigate();
  const [form] = Form.useForm();


  const documentColumn = [
    {
      title: location.state.type ? 'Video Title' : 'Name',
      key: "name",
      dataIndex: location.state.type ? "videoTitle" : "name",
    },
    {
      title: 'Version',
      key: "version",
      dataIndex: "version",
    },
    {
      title: 'Date',
      key: "created_date",
      dataIndex: "created_date",
      render: (e) => <span>{new Date(e).toLocaleString('en-US', dateOption)}</span>
    },
    {
      title: 'Status',
      key: "status",
      dataIndex: "status",
      render: (e) => e == 0 ? <span>Obsolete</span> : e == 2 ? <span>Processing</span> : <span>Active</span>
    },
    {
      title: 'Section',
      key: "section",
      dataIndex: "section",
      render: (e) => e == 0 ? <span>EMS</span> : <span>Fire</span>
    },
    {
      title: 'Type',
      key: "type",
      dataIndex: "type",
      render: (e) => e == 0 ? <span>Document</span> : <span>Video</span>
    },
    {
      title: 'Action',
      key: "action",
      render: (e) =>
        <div>
          <Button
            type="link"
            onClick={async () => {
              setSelectedDocument(e);
              if (location.state.type == 0) {
                await form.setFieldsValue({ version: e.version })
                await form.setFieldsValue({ page: e.page })
                await form.setFieldsValue({ notification: e.notification })
                setEditVersionModal(true)
              } else {
                await form.setFieldsValue({ videoTitle: e.videoTitle })
                await form.setFieldsValue({ youtube: e.youtube })
                await form.setFieldsValue({ version: e.version })
                await form.setFieldsValue({ notification: e.notification })
                setEditVersionModal(true)
              }
            }}
            size='small'
          >
            Edit
          </Button>
          <Popconfirm
            placement="right"
            title={"Confirm!"}
            description={"Are you sure you want to remove this document?"}
            onConfirm={() => {
              removeDocument(e)
            }}
            okText="Yes"
            cancelText="No"
          >
            <Button type="link" danger>Delete</Button>
          </Popconfirm>
        </div>
    },
  ]

  const dateOption = {
    timeZone: 'America/New_York',
    year: 'numeric',
    month: 'numeric',
    day: 'numeric'
  };

  const [userData, setUserData] = useState(null);
  const [documentData, setDocumentData] = useState(null);
  const [documentList, setDocumentList] = useState([]);
  const [createVersionModal, setCreateVersionModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [createLoading, setCreateLoading] = useState(false);
  const [uploadPercent, setUploadPercent] = useState(1);
  const [documentFile, setDocumentFile] = useState(null);
  const [editVersionModal, setEditVersionModal] = useState(false);
  const [updateDocumentFile, setUpdateDocumentFile] = useState(null);
  const [selectedDocument, setSelectedDocument] = useState(null);

  const sortFunction = (a, b) => {
    if (a.version === b.version) {
      return 0;
    }
    else {
      return (a.version > b.version) ? -1 : 1;
    }
  }


  const init = () => {
    if (location.state) {
      setDocumentData(location.state);
      if (localStorage.getItem('user')) {
        setLoading(true);
        const user = JSON.parse(localStorage.getItem("user"));
        setUserData(user);

        const docId = location.state.parent ? location.state.parent : location.state.id

        getDocumentByParent({ id: docId })
          .then((response) => {
            setDocumentList(response.sort(sortFunction));
            setLoading(false);
          })
          .catch((err) => {
            setLoading(false);
            console.log(err);
            message.error("Something went wrong while fetching document data!");
          })
      }
      else navigate("/login");
    } else {
      navigate(-1)
    }
  }

  useEffect(() => {
    init()
  }, [])

  const DocumentListView = () => {
    return (
      <Row style={{ marginTop: 20 }}>
        <Col span={24} className="create-invoice-container">
          <Button type="primary" onClick={() => setCreateVersionModal(true)}>Add New Version</Button>
        </Col>
        <Col span={24}>
          <Table
            rowKey={record => record.id}
            dataSource={documentList}
            columns={documentColumn}
            loading={loading}
          />
        </Col>
      </Row>
    )
  }

  const removeDocument = (doc) => {
    if (doc.parent) {
      if (doc.status == 1) {
        setLoading(true);
        const updateDoc = documentList[1];
        removeDocumentData(doc.id)
          .then((response) => {
            updateDocumentStatus(updateDoc.id)
              .then((response) => {
                setLoading(false);
                init();
              })
              .catch((err) => {
                console.log(err);
                setLoading(false);
                message.error("Something went wrong while removing data, please try again later!");
              })

          })
          .catch((err) => {
            console.log(err);
            setLoading(false);
            message.error("Something went wrong while removing data, please try again later!");
          })
      } else {
        removeDocumentData(doc.id)
          .then((response) => {
            setLoading(false);
            init();
          })
          .catch((err) => {
            console.log(err);
            setLoading(false);
            message.error("Something went wrong while removing data, please try again later!");
          })
      }
    } else {
      setLoading(true);
      removeDocumentData(doc.id)
        .then((response) => {
          setLoading(false);
          navigate(-1)
        })
        .catch((err) => {
          console.log(err);
          setLoading(false);
          message.error("Something went wrong while removing data, please try again later!");
        })
    }
  }


  const addDocument = (values) => {
    if (location.state.type == 0) {
      if (documentFile) {
        setCreateLoading(true);
        const documentName = new Date().getTime() + "-" + documentFile.name;

        fileUploadToStroage({ file: documentFile, name: documentName }, (snapshot) => {
          setUploadPercent(parseInt(snapshot.bytesTransferred * 100 / snapshot.totalBytes))
        })
          .then((response) => {
            const uri = response;
            const gcs_uri = "gs://protocol-pro.appspot.com/documents/" + documentName;

            values.client = documentData.client
            values.name = documentData.name
            values.section = documentData.section
            values.type = documentData.type
            values.document = uri;
            values.created_date = new Date().toISOString();
            values.status = 1;
            values.parent = documentData.parent ? documentData.parent : documentData.id;


            setCreateLoading(false);
            setCreateVersionModal(false);
            form.resetFields();

            convertDocumentAi({ document: gcs_uri, values: values })
              .then((response) => {
                message.success("New version of document has been pushed");

                init();
              })
              .catch((err) => {
                console.log(err);
                message.error("Something went wrong while uploading document, please try again later!");
              })
          })
          .catch((err) => {
            console.log(err);
            setCreateLoading(false);
            message.error("Something went wrong while uploading document, please try again later!");
          })
      } else {
        message.error("Something went wrong while uploading document, please try again later!");
      }
    } else {
      setCreateLoading(true);
      values.client = documentData.client
      values.name = documentData.name
      values.section = documentData.section
      values.type = documentData.type
      values.created_date = new Date().toISOString();
      values.status = 1;
      values.parent = documentData.parent ? documentData.parent : documentData.id;

      addDocumentData(values)
        .then(async (response) => {
          setCreateLoading(false);
          setCreateVersionModal(false);
          message.success("Video data has been published!");
          init();

          await sendContentNotification({
            text: values.notification,
            client: location.state.client
          })
          window.location.reload(false);
        })
        .catch((err) => {
          message.error("Something went wrong while uploading document, please try again later!");

        })
    }
  }

  const updateDocument = (values) => {
    if (location.state.type == 0) {
      if (updateDocumentFile) {
        setCreateLoading(true);
        const documentName = new Date().getTime() + "-" + updateDocumentFile.name;
        setUploadPercent(1);

        fileUploadToStroage({ file: updateDocumentFile, name: documentName }, (snapshot) => {
          setUploadPercent(parseInt(snapshot.bytesTransferred * 100 / snapshot.totalBytes))
        })
          .then((response) => {
            setCreateLoading(false);
            setCreateVersionModal(false);
            const uri = response;
            const gcs_uri = "gs://protocol-pro.appspot.com/documents/" + documentName;

            const param = {
              id: selectedDocument.id,
              version: values.version,
              page: values.page,
              client: selectedDocument.client,
              notification: values.notification,
              document: uri,
              created_date: new Date().toISOString()
            }

            setEditVersionModal(false);

            form.resetFields();

            convertAndUpdateDocumentAi({ document: gcs_uri, values: param })
              .then((response) => {
                message.success("New version of document has been pushed");

                // init();
                // reload document table after 1s - added by Alex
                window.setTimeout(() => {
                  init();
                }, 1000);
              })
              .catch((err) => {
                console.log(err);
                message.error("Something went wrong while updating document, please try again later!");
              });


          })
          .catch((err) => {
            console.log(err);
            setCreateLoading(false);
            setEditVersionModal(false);
            message.error("Something went wrong while updating document, please try again later!");
          })
      } else {
        setCreateLoading(true);
        const param = {
          id: selectedDocument.id,
          version: values.version,
          page: values.page,
          notification: values.notification,
          created_date: new Date().toISOString()
        }
        setUploadPercent(100);
        updateDocumentInfo(param)
          .then(async (response) => {
            setCreateLoading(false);
            setEditVersionModal(false);
            await sendContentNotification({
              text: values.notification,
              client: location.state.client
            });
            init();
          })
          .catch((err) => {
            setCreateLoading(false);
            setEditVersionModal(false);
            message.error("Something went wrong while updating document, please try again later!");
            console.log(err);
          })
      }
    } else {
      setCreateLoading(true);
      const param = {
        id: selectedDocument.id,
        version: values.version,
        videoTitle: values.videoTitle,
        youtube: values.youtube,
        notification: values.notification,
        created_date: new Date().toISOString()
      }

      updateVideoInfo(param)
        .then(async (response) => {
          setCreateLoading(false);
          setEditVersionModal(false);
          await sendContentNotification({
            text: values.notification,
            client: location.state.client
          });
          init();
        })
        .catch((err) => {
          setCreateLoading(false);
          setEditVersionModal(false);
          message.error("Something went wrong while updating document, please try again later!");
          console.log(err);
        })
    }
  }

  return (
    <>
      {
        userData?.role == 0 ?
          <Sidebar>
            <DocumentListView />
          </Sidebar>
          :
          <ClientSideBar>
            <DocumentListView />
          </ClientSideBar>
      }


      <Modal
        title="Add New Version"
        open={createVersionModal}
        onCancel={() => setCreateVersionModal(false)}
        footer={false}
      >
        <Row>
          <Col span={24}>
            <Form
              layout="vertical"
              wrapperCol={{ span: 24 }}
              onFinish={addDocument}
              onFinishFailed={() => message.error("Something went wrong, please try again later!")}
              autoComplete="off"
            >
              {
                location.state?.type == 0 ?

                  <Row gutter={[12, 0]}>
                    <Col span={12}>
                      <Form.Item
                        label="Version"
                        name="version"
                        rules={[{ required: true, message: 'Please input version!' }]}
                      >
                        <Input
                          size="medium"
                          placeholder="Version"
                          type='number'
                        />
                      </Form.Item>
                    </Col>

                    <Col span={24}>
                      <Form.Item
                        label="Document"
                        name="document"
                        rules={[{ required: true, message: 'Please upload document!' }]}
                      >
                        <input name="document" type="file" onChange={(e) => setDocumentFile(e.target.files[0])} />
                      </Form.Item>
                    </Col>

                    <Col span={24}>
                      <Form.Item
                        label="Notification Text"
                        name="notification"
                        rules={[{ required: true, message: 'Please input notification text!' }]}
                      >
                        <TextArea
                          size="medium"
                          placeholder="Notification Text"
                        />
                      </Form.Item>
                    </Col>

                    <Col span={24}>
                      <Form.Item
                        label="Page number for annotated updates"
                        name="page"
                        rules={[{ required: false, message: 'Please input updated page number!' }]}
                      >
                        <Input
                          type="number"
                          size="medium"
                          placeholder="Page Number"
                        />
                      </Form.Item>
                    </Col>

                    {
                      createLoading &&
                      <Col span={24}>
                        <Progress percent={uploadPercent} />
                      </Col>
                    }

                  </Row>
                  :
                  <Row gutter={[12, 0]}>
                    <Col span={12}>
                      <Form.Item
                        label="Video Title"
                        name="videoTitle"
                        rules={[{ required: true, message: 'Please input title!' }]}
                      >
                        <Input
                          size="medium"
                          placeholder="Title"
                          type='text'
                        />
                      </Form.Item>
                    </Col>

                    <Col span={12}>
                      <Form.Item
                        label="Version"
                        name="version"
                        rules={[{ required: true, message: 'Please input version!' }]}
                      >
                        <Input
                          size="medium"
                          placeholder="Version"
                          type='number'
                        />
                      </Form.Item>
                    </Col>

                    <Col span={24}>
                      <Form.Item
                        label="Youtube Link"
                        name="youtube"
                        rules={[{ required: true, message: 'Please input youtube link!' }]}
                      >
                        <Input
                          size="medium"
                          placeholder="youtube"
                          type='text'
                        />
                      </Form.Item>
                    </Col>

                    <Col span={24}>
                      <Form.Item
                        label="Notification Text"
                        name="notification"
                        rules={[{ required: true, message: 'Please input notification text!' }]}
                      >
                        <TextArea
                          size="medium"
                          placeholder="Notification Text"
                        />
                      </Form.Item>
                    </Col>
                  </Row>
              }

              <Form.Item wrapperCol={{ span: 24 }} className="text-center">
                <Button
                  type="primary"
                  size={"medium"}
                  htmlType="submit"
                  loading={createLoading}
                >
                  Create New Version
                </Button>
              </Form.Item>
            </Form>
          </Col>
        </Row>
      </Modal>

      <Modal
        title="Update Document"
        open={editVersionModal}
        onCancel={() => setEditVersionModal(false)}
        footer={false}

      >
        <Row>
          <Col span={24}>
            <Form
              layout="vertical"
              wrapperCol={{ span: 24 }}
              onFinish={updateDocument}
              onFinishFailed={() => message.error("Something went wrong, please try again later!")}
              autoComplete="off"
              form={form}
            >
              {
                location.state?.type == 0 ?
                  <Row gutter={[12, 0]}>
                    <Col span={12}>
                      <Form.Item
                        label="Version"
                        name="version"
                        rules={[{ required: true, message: 'Please input version!' }]}
                      >
                        <Input
                          size="medium"
                          placeholder="Version"
                          type='number'
                        />
                      </Form.Item>
                    </Col>

                    <Col span={24}>
                      <Form.Item
                        label="Update document by clicking button"
                        name="document"
                      >
                        <input name="document" type="file" onChange={(e) => setUpdateDocumentFile(e.target.files[0])} />
                      </Form.Item>
                    </Col>

                    <Col span={24}>
                      <Form.Item
                        label="Notification Text"
                        name="notification"
                        rules={[{ required: true, message: 'Please input notification text!' }]}
                      >
                        <TextArea
                          size="medium"
                          placeholder="Notification Text"
                        />
                      </Form.Item>
                    </Col>


                    <Col span={24}>
                      <Form.Item
                        label="Page number for annotated updates"
                        name="page"
                        rules={[{ required: false, message: 'Please input updated page number!' }]}
                      >
                        <Input
                          type="number"
                          size="medium"
                          placeholder="Notification Text"
                        />
                      </Form.Item>
                    </Col>

                    {
                      createLoading &&
                      <Col span={24}>
                        <Progress percent={uploadPercent} />
                      </Col>
                    }

                  </Row>
                  :
                  <Row gutter={[12, 0]}>
                    <Col span={12}>
                      <Form.Item
                        label="Video Title"
                        name="videoTitle"
                        rules={[{ required: true, message: 'Please input title!' }]}
                      >
                        <Input
                          size="medium"
                          placeholder="Title"
                          type='text'
                        />
                      </Form.Item>
                    </Col>

                    <Col span={12}>
                      <Form.Item
                        label="Version"
                        name="version"
                        rules={[{ required: true, message: 'Please input version!' }]}
                      >
                        <Input
                          size="medium"
                          placeholder="Version"
                          type='number'
                        />
                      </Form.Item>
                    </Col>

                    <Col span={24}>
                      <Form.Item
                        label="Youtube Link"
                        name="youtube"
                        rules={[{ required: true, message: 'Please input youtube link!' }]}
                      >
                        <Input
                          size="medium"
                          placeholder="youtube"
                          type='text'
                        />
                      </Form.Item>
                    </Col>

                    <Col span={24}>
                      <Form.Item
                        label="Notification Text"
                        name="notification"
                        rules={[{ required: true, message: 'Please input notification text!' }]}
                      >
                        <TextArea
                          size="medium"
                          placeholder="Notification Text"
                        />
                      </Form.Item>
                    </Col>
                  </Row>

              }

              <Form.Item wrapperCol={{ span: 24 }} className="text-center">
                <Button
                  type="primary"
                  size={"medium"}
                  htmlType="submit"
                  loading={createLoading}
                >
                  Update Version
                </Button>
              </Form.Item>
            </Form>
          </Col>
        </Row>
      </Modal>
    </>
  )
}
