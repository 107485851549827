import { ExportOutlined, LockOutlined, UserOutlined } from '@ant-design/icons';
import { Button, Card, Col, Form, Input, message, Row } from 'antd';
import { React, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { checkClientAdminCredential, getClientDataByAdmin } from '../script/auth';
import "./style.css";

export default function LoginScreen() {
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();

  const handleLogin = async (values) => {
    setLoading(true);
    let email = values.email;
    checkClientAdminCredential(values)
      .then((response) => {
        if (response) {
          getClientDataByAdmin({ email: email })
            .then(async (response) => {
              await localStorage.setItem("user", JSON.stringify({ email: email, role: 1, client: response }));
              setLoading(false);
              navigate("/client-content");
            })
            .catch((err) => {
              setLoading(false);
              console.log(err);
              message.error("Something went wrong, please try again later!");
            })
        } else {
          message.error("You entered invalid credential. Please check your credential again!");
          setLoading(false);
        }
      })
      .catch((err) => {
        console.log(err);
        message.error("Something went wrong, please try again later!");
        setLoading(false);
      })

  };

  return (
    <Row align={"middle"}>
      <Col xs={{ span: 22, offset: 1 }} md={{ span: 12, offset: 6 }}>
        <Card style={{ marginTop: "20%" }}>
          <Row>
            <Col span={24} className="text-center">
              <img src={require("../assets/image/logo.png")} className="logo" />
            </Col>

            <Col span={24} style={{ marginTop: 50 }}>
              <Form
                layout="vertical"
                wrapperCol={{ span: 24 }}
                initialValues={{ remember: true }}
                onFinish={handleLogin}
                autoComplete="off"
              >
                <Form.Item
                  label="Email"
                  name="email"
                  rules={[{ required: true, message: 'Please input your email!' }]}
                >
                  <Input
                    size="large"
                    placeholder="Email"
                    prefix={<UserOutlined />}
                  />
                </Form.Item>
                <Form.Item
                  label="Password"
                  name="password"
                  rules={[{ required: true, message: 'Please input your password!' }]}
                >
                  <Input
                    type='password'
                    size="large"
                    placeholder="Password"
                    prefix={<LockOutlined />}
                  />
                </Form.Item>

                <Form.Item wrapperCol={{ span: 24 }} className="text-center">
                  <Button
                    type="primary"
                    icon={<ExportOutlined />}
                    size={"large"}
                    htmlType="submit"
                    loading={loading}
                  >
                    Log In
                  </Button>
                </Form.Item>
              </Form>
            </Col>
          </Row>
        </Card>
      </Col>
    </Row>
  );
};
