import { Button, Col, Form, Input, message, Modal, Popconfirm, Row, Table } from 'antd';
import Papa from "papaparse";
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { ClientSideBar } from '../../container/ClientSideBar';
import { addUserToClient, addUserToLog, checkUserExist, getClientDataByID, getClientUser } from '../../script/auth';

export default function ClientUser() {

  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [userData, setUserData] = useState(null);
  const [addAccountModal, setAddAccountModal] = useState(false);
  const [clientData, setClientData] = useState(null);
  const [createLoading, setCreateLoading] = useState(false);


  const userColumn = [
    {
      title: 'First Name',
      dataIndex: "first_name",
      key: "first_name",
      render: (e) => e ? <span>{e}</span> : null
    },
    {
      title: 'Last Name',
      dataIndex: "last_name",
      key: "last_name",
      render: (e) => e ? <span>{e}</span> : null
    },
    {
      title: 'Email',
      key: "email",
      render: (e) => e.email ? <span>{e.email}</span> : <span>{e}</span>
    },
    {
      title: 'Action',
      key: 'action',
      render: (e) =>
        <Popconfirm
          placement="right"
          title={"Confirm!"}
          description={"Are you sure you want to remove this user?"}
          onConfirm={() => removeUser(e)}
          okText="Yes"
          cancelText="No"
        >
          <Button type="link" danger>Delete</Button>
        </Popconfirm>
    }
  ]


  const init = async () => {
    const data = await localStorage.getItem("user");
    try {
      if (data) {
        const user = JSON.parse(data);
        setClientData(user.client);
        if (user.role == 0) {
          navigate("/client-login");
        } else {
          getClientUser({ id: user.client.id })
            .then((response) => {
              console.log(response);
              setUserData(response);
              setLoading(false);
            })
            .catch((err) => {
              console.log(err);
              setLoading(false);
              message.error("Something went wrong while fetching client data, please try again later!");
            })
        }
      }
    }
    catch (err) {
      console.log(err)
      navigate("/client-login");
    }
  }

  useEffect(() => {
    if (localStorage.getItem('user')) {
      const user = JSON.parse(localStorage.getItem("user"));
      if (user.role == 0) {
        navigate("/client-login");
      } else {
        init();
      }
    }
    else navigate("/client-login");
  }, [])

  const removeUser = (user) => {
    setLoading(true);
    var users = userData;
    var index = users.indexOf(user);
    if (index !== -1) {
      users.splice(index, 1);
    }


    const uniqueArray = users.filter((obj, index, arr) => {
      return arr.findIndex((t) => t.email === obj.email) === index;
    });

    addUserToClient({ id: clientData.id, user: uniqueArray, type: "remove", removeUser: user })
      .then(async (response) => {
        if (response.status == "success") {
          init();
          message.success("User has been removed!");
        }
        else message.error("Something went wrong while removing a user data, please try again later!");
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
        message.error("Something went wrong while removing a user data, please try again later!");
      })
  }

  const handleParse = (file) => {
    if (!file) return message.error("Enter a valid file");
    const reader = new FileReader();
    reader.onload = async ({ target }) => {
      const csv = Papa.parse(target.result, { header: false });
      const parsedData = csv?.data;

      var userData = [];
      parsedData.map((data) => {
        if (data[0] != "") userData.push({ email: data[0], first_name: "", last_name: "" });
      })

      const newUser = clientData.users.concat(userData);

      const uniqueArray = newUser.filter((obj, index, arr) => {
        return arr.findIndex((t) => t.email === obj.email) === index;
      });
      addUserToClient({ id: clientData.id, user: uniqueArray, newUser: userData })
        .then(async (response) => {
          setLoading(false);
          if (response.status == "success") {
            message.success("User data has been uploaded!");
            init();
          }
          else message.error("Something went wrong while saving user data, please try again later!");
        })
        .catch((err) => {
          console.log(err);
          setLoading(false);
          message.error("Something went wrong while saving user data, please try again later!");
        })
    };
    reader.readAsText(file);
  };

  const handleFileChange = (e) => {
    setLoading(true);
    const allowedExtensions = ["csv"];
    if (e.target.files.length) {
      const inputFile = e.target.files[0];
      const fileExtension = inputFile?.type.split("/")[1];
      if (!allowedExtensions.includes(fileExtension)) {
        alert("Please input a csv file");
        return;
      }
      handleParse(inputFile);
    }
  };


  const addAccountUser = (values) => {
    setCreateLoading(true);

    checkUserExist(values)
      .then((response) => {
        if (response == "no-exist") {
          var user = userData;
          user.push(values);

          const uniqueArray = user.filter((obj, index, arr) => {
            return arr.findIndex((t) => t.email === obj.email) === index;
          });
          addUserToClient({ id: clientData.id, user: uniqueArray, newUser: [values] })
            .then(async (response) => {
              setCreateLoading(false);
              if (response.status == "success") message.success("User data has been added!");
              else message.error("Something went wrong while saving user data, please try again later!");
              init();
              setAddAccountModal(false);

            })
            .catch((err) => {
              console.log(err);
              setAddAccountModal(false);
              setCreateLoading(false);
              message.error("Something went wrong while saving user data, please try again later!");
            })
        } else {
          setCreateLoading(false);
          message.error("This email address is already associated")
        }
      })
      .catch((err) => {
        console.log(err);
        setCreateLoading(false);
      })

  }

  return (
    <ClientSideBar>
      <Row>
        <Col span={24} className="contact-line-view">
          <h4>Account Users</h4>
          <Button type="link" onClick={() => setAddAccountModal(true)}>
            (+)
          </Button>
          <input
            onChange={handleFileChange}
            className="csv-input"
            name="file"
            type="File"
          />
        </Col>
        {
          clientData &&
          <Col span={24}>
            <Table
              loading={loading}
              style={{ width: "600px" }}
              columns={userColumn}
              dataSource={userData}
            />
          </Col>
        }
      </Row>


      <Modal
        title="Add Account"
        open={addAccountModal}
        onCancel={() => setAddAccountModal(false)}
        footer={false}
      >
        <Row>
          <Col span={24}>
            <Form
              layout="vertical"
              wrapperCol={{ span: 24 }}
              onFinish={addAccountUser}
              onFinishFailed={() => message.error("Something went wrong, please try again later!")}
              autoComplete="off"
            >
              <Row gutter={[12, 0]}>
                <Col span={12}>
                  <Form.Item
                    label="First Name"
                    name="first_name"
                    rules={[{ required: true, message: 'Please input first name!' }]}
                  >
                    <Input
                      size="medium"
                      placeholder="Name"
                    />
                  </Form.Item>
                </Col>

                <Col span={12}>
                  <Form.Item
                    label="Last Name"
                    name="last_name"
                    rules={[{ required: true, message: 'Please input last name!' }]}
                  >
                    <Input
                      size="medium"
                      placeholder="Name"
                    />
                  </Form.Item>
                </Col>

                <Col span={24}>
                  <Form.Item
                    label="Email"
                    name="email"
                    rules={[{ required: true, message: 'Please input email address!' }]}
                  >
                    <Input
                      size="medium"
                      placeholder="Name"
                    />
                  </Form.Item>
                </Col>

              </Row>

              <Form.Item wrapperCol={{ span: 24 }} className="text-center">
                <Button
                  type="primary"
                  size={"medium"}
                  htmlType="submit"
                  loading={createLoading}
                >
                  Create Account
                </Button>
              </Form.Item>
            </Form>
          </Col>
        </Row>
      </Modal>

    </ClientSideBar>
  )
}
