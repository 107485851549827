import { UserAddOutlined, LockOutlined, UserOutlined } from '@ant-design/icons';
import { Button, Card, Col, Form, Input, message, Row } from 'antd';
import { React, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { addClientAdminData, addClientData, checkClientAdminCredential, checkClientAdminExist, checkClientExist, getClientDataByAdmin } from '../script/auth';
import "./style.css";

export default function ClientAdminSignUpScreen() {
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();

  const handleSignup = async (values) => {
    setLoading(true);

    const clientData = {
      organization: values.organization,
      name: values.first_name + " " + values.last_name,
      address: values.address,
      phone: values.phone,
      email: values.email.toLowerCase().replaceAll(" ", ""),
      domain: values.domain,
      users: [],
      partner: null
    }

    var adminData = {
      first_name: values.first_name,
      last_name: values.last_name,
      email: values.email.toLowerCase().replaceAll(" ", ""),
      password: btoa(values.password),
    }

    checkClientExist(clientData)
      .then((res) => {
        if (res == "exist") {
          message.error("Email is already exist!");
          setLoading(false);
        } else {
          checkClientAdminExist(adminData)
            .then((res) => {
              if (res == "exist") {
                message.error("Email is already exist!");
                setLoading(false);
              } else {
                addClientData(clientData)
                  .then((response) => {
                    if (response) {
                      adminData.client = response;
                      addClientAdminData(adminData)
                        .then((res) => {
                          message.success("Account created successfully!");
                          setLoading(false);
                          navigate("/client-login");
                        })
                        .catch((err) => {
                          message.error("Something went wrong!");
                          setLoading(false);
                        })
                    } else {
                      message.error("Something went wrong!");
                      setLoading(false);
                    }
                  })
                  .catch((err) => {
                    message.error("Something went wrong!");
                    setLoading(false);
                  })
              }
            })
            .catch((err) => {
              message.error("Something went wrong!");
              setLoading(false);
            })
        }
      })
      .catch((err) => {
        message.error("Something went wrong!");
        setLoading(false);
      })
  };

  return (
    <Row align={"middle"}>
      <Col xs={{ span: 22, offset: 1 }} md={{ span: 12, offset: 6 }}>
        <Card style={{ marginTop: 20 }}>
          <Row>
            <Col span={24} className="text-center">
              <img src={require("../assets/image/logo.png")} className="logo" />
            </Col>

            <Col span={24} style={{ marginTop: 20 }}>
              <Form
                layout="vertical"
                wrapperCol={{ span: 24 }}
                initialValues={{ remember: true }}
                onFinish={handleSignup}
                autoComplete="off"
              >
                <Row gutter={6}>
                  <Col span={24}>
                    <Form.Item
                      label="Organization Name"
                      name="organization"
                      rules={[{ required: true, message: 'Please input organization!' }]}
                    >
                      <Input
                        size="medium"
                        placeholder="Organization Name"
                      />
                    </Form.Item>
                  </Col>

                  <Col span={12}>
                    <Form.Item
                      label="First Name"
                      name="first_name"
                      rules={[{ required: true, message: 'Please input first name!' }]}
                    >
                      <Input
                        size="medium"
                        placeholder="First Name"
                      />
                    </Form.Item>
                  </Col>

                  <Col span={12}>
                    <Form.Item
                      label="Last Name"
                      name="last_name"
                      rules={[{ required: true, message: 'Please input last name!' }]}
                    >
                      <Input
                        size="medium"
                        placeholder="Last Name"
                      />
                    </Form.Item>
                  </Col>

                  <Col span={24}>
                    <Form.Item
                      label="Address"
                      name="address"
                      rules={[{ required: true, message: 'Please input address!' }]}
                    >
                      <Input
                        size="medium"
                        placeholder="Address"
                      />
                    </Form.Item>
                  </Col>

                  <Col span={24}>
                    <Form.Item
                      label="Phone Number"
                      name="phone"
                      rules={[{ required: true, message: 'Please input phone number!' }]}
                    >
                      <Input
                        size="medium"
                        placeholder="Phone Number"
                      />
                    </Form.Item>
                  </Col>

                  <Col span={24}>
                    <Form.Item
                      label="Email"
                      name="email"
                      rules={[{ required: true, message: 'Please input email!' }]}
                    >
                      <Input
                        size="medium"
                        placeholder="Email"
                      />
                    </Form.Item>
                  </Col>

                  <Col span={24}>
                    <Form.Item
                      label="Password"
                      name="password"
                      rules={[{ required: true, message: 'Please input password!' }]}
                    >
                      <Input
                        size="medium"
                        placeholder="Password"
                        type='password'
                      />
                    </Form.Item>
                  </Col>

                  <Col span={24}>
                    <Form.Item
                      label="Organization Domain: Sample(@yourfiredpt.gov)"
                      name="domain"
                      rules={[{ required: true, message: 'Please input domain!' }]}
                    >
                      <Input
                        size="medium"
                        placeholder="Organization Domain"
                      />
                    </Form.Item>
                  </Col>

                </Row>


                <Form.Item wrapperCol={{ span: 24 }} className="text-center">
                  <Button
                    type="primary"
                    icon={<UserAddOutlined />}
                    size={"large"}
                    htmlType="submit"
                    loading={loading}
                  >
                    Create Account
                  </Button>
                </Form.Item>
              </Form>
            </Col>
          </Row>
        </Card>
      </Col>
    </Row>
  );
};
