import { PlusOutlined, SendOutlined } from '@ant-design/icons';
import { Button, Col, DatePicker, Form, Input, Modal, Popconfirm, Progress, Row, Select, Table, Tabs, message } from 'antd';
import Papa from "papaparse";
import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { convertDocumentAi, sendInvoiceToCustomer, sendPasswordSetEmail } from '../../api/point';
import { Sidebar } from '../../container/Sidebar';
import { addClientAdminData, addContactData, addInvoiceData, addUserToClient, checkUserExist, getClientAdminByID, getClientDataByID, getClientInvoiceData, getClientUser, getContactByID, getPartnerData, removeClientAdminData, removeContactData, sendContentNotification, updateClientAdminData, updateClientData, updateClientDomain } from '../../script/auth';
import { addDocumentData, getClientDocumentByID } from '../../script/document';
import { fileUploadToStroage } from '../../script/file';

export default function ClientDetail() {

  const location = useLocation();
  const navigate = useNavigate();
  const { TextArea } = Input;

  const [clientData, setClientData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [updateLoading, setUpdateLoading] = useState(false);
  const [createLoading, setCreateLoading] = useState(false);
  const [editClientModal, setEditClientModal] = useState(false);
  const [editDomainModal, setEditDomainModal] = useState(false);
  const [createInvoiceModal, setCreateInvoiceModal] = useState(false);
  const [createContentModal, setCreateContentModal] = useState(false);
  const [documentData, setDocumentData] = useState([]);
  const [documentFile, setDocumentFile] = useState(null);
  const [createUserModal, setCreateUserModal] = useState(false);
  const [clientAdminData, setClientAdminData] = useState([]);
  const [selectedAdminData, setSelectedAdminData] = useState(null);
  const [editUserModal, setEditUserModal] = useState(false);
  const [emailList, setEmailList] = useState([]);
  const [itemList, setItemList] = useState([{ name: "", price: null }]);
  const [invoiceDate, setInvoiceDate] = useState(null);
  const [paymentDueDate, setPaymentDueDate] = useState(null);
  const [invoiceLoading, setInvoiceLoading] = useState(false);
  const [clientInvoice, setClientInvoice] = useState([]);
  const [partnerData, setPartnerData] = useState([]);
  const [invoicePartner, setInvoicePartner] = useState(null);
  const [contactList, setContactList] = useState([]);
  const [createContactModal, setCreateContactModal] = useState(false);
  const [documentType, setDocumentType] = useState(0);
  const [addAccountModal, setAddAccountModal] = useState(false);
  const [uploadPercent, setUploadPercent] = useState(1);
  const [userData, setUserData] = useState([]);

  const dateOption = {
    timeZone: 'America/New_York',
    year: 'numeric',
    month: 'numeric',
    day: 'numeric'
  };

  const init = () => {
    setLoading(true);
    getClientDataByID({ id: location.state })
      .then((response) => {
        setClientData(response);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
        message.error("Something went wrong while fetching client data, please try again later!");
      })

    getClientUser({ id: location.state })
      .then((response) => {
        setUserData(response);
      })
      .catch((err) => {
        console.log(err);
      })

    getClientDocumentByID({ client: location.state })
      .then((response) => {
        setDocumentData(response);
      })
      .catch((err) => {
        console.log(err);
        message.error("Something went wrong while fetching client document data, please try again later!");
      })

    getClientAdminByID({ client: location.state })
      .then((response) => {
        setClientAdminData(response);
      })
      .catch((err) => {
        console.log(err);
        message.error("Something went wrong while fetching client admin data, please try again later!");
      })

    getClientInvoiceData({ client: location.state })
      .then((response) => {
        function compare(a, b) {
          if (a.invoiceDate < b.invoiceDate) {
            return 1;
          }
          if (a.invoiceDate > b.invoiceDate) {
            return -1;
          }
          return 0;
        }

        setClientInvoice(response.sort(compare));
      })
      .catch((err) => {
        console.log(err);
        message.error("Something went wrong while fetching client invoice data, please try again later!");
      })

    getPartnerData()
      .then((response) => {
        setPartnerData(response);
      })
      .catch((err) => {
        console.log(err);
        message.error("Something went wrong while fetching partner invoice data, please try again later!");
      })

    getContactByID({ client: location.state })
      .then((response) => {
        setContactList(response);
      })
      .catch((err) => {
        console.log(err);
        message.error("Something went wrong while fetching contact data, please try again later!");
      })
  }

  useEffect(() => {
    if (location.state)
      if (localStorage.getItem('user')) {
        const user = JSON.parse(localStorage.getItem("user"));
        if (user.role == 1) {
          navigate("/login");
        } else {
          init();
        }
      }
      else navigate("/login");
    else
      navigate("/client");
  }, [])

  const userColumn = [
    {
      title: 'First Name',
      dataIndex: "first_name",
      key: "first_name",
      render: (e) => e ? <span>{e}</span> : null
    },
    {
      title: 'Last Name',
      dataIndex: "last_name",
      key: "last_name",
      render: (e) => e ? <span>{e}</span> : null
    },
    {
      title: 'Email',
      key: "email",
      render: (e) => e.email ? <span>{e.email}</span> : <span>{e}</span>
    },
    {
      title: 'Action',
      key: 'action',
      render: (e) =>
        <Popconfirm
          placement="right"
          title={"Confirm!"}
          description={"Are you sure you want to remove this user?"}
          onConfirm={() => removeUser(e)}
          okText="Yes"
          cancelText="No"
        >
          <Button type="link" danger>Delete</Button>
        </Popconfirm>
    }
  ]

  const documentColumn = [
    {
      title: 'Name',
      key: "name",
      dataIndex: "name",
    },
    {
      title: 'Version',
      key: "version",
      dataIndex: "version",
    },
    {
      title: 'Date',
      key: "created_date",
      dataIndex: "created_date",
      render: (e) => <span>{new Date(e).toLocaleString('en-US', dateOption)}</span>
    },
    {
      title: 'Status',
      key: "status",
      dataIndex: "status",
      render: (e) => e == 0 ? <span>Waiting Approval</span> : <span>Active</span>
    },
    {
      title: 'Section',
      key: "section",
      dataIndex: "section",
      render: (e) => e == 0 ? <span>EMS</span> : <span>Fire</span>
    },
    {
      title: 'Type',
      key: "type",
      dataIndex: "type",
      render: (e) => e == 0 ? <span>Document</span> : <span>Video</span>
    },
    {
      title: 'Action',
      key: "action",
      render: (e) =>
        <Button type="primary" size='small' onClick={() => navigate("/document-detail", { state: e })}>View</Button>
    },
  ]

  const adminColumn = [
    {
      title: 'First Name',
      key: "first_name",
      dataIndex: "first_name",
    },
    {
      title: 'Last Name',
      key: "last_name",
      dataIndex: "last_name",
    },
    {
      title: 'Email',
      key: "email",
      dataIndex: "email",
    },
    {
      title: 'Role',
      key: "role",
      dataIndex: "role",
      render: (e) => e == 0 ? <span>Admin</span> : <span>Editor</span>
    },
    {
      title: 'Action',
      key: "action",
      render: (e) =>
        <>
          <Button size='small' type="primary" onClick={() => { setSelectedAdminData(e); setEditUserModal(true) }}>Edit</Button>
          <Popconfirm
            placement="right"
            title={"Confirm!"}
            description={"Are you sure you want to remove this user?"}
            onConfirm={() => removeClientAdmin(e)}
            okText="Yes"
            cancelText="No"
          >
            <Button size='small' type="primary" danger>
              Delete
            </Button>
          </Popconfirm>
        </>
    }
  ]

  const contactColumn = [
    {
      title: 'Title',
      key: "title",
      dataIndex: "title",
    },
    {
      title: 'Name',
      key: "first_name",
      dataIndex: "first_name",
    },
    {
      title: 'Last Name',
      key: "last_name",
      dataIndex: "last_name",
    },
    {
      title: 'Organization',
      key: "organization",
      dataIndex: "organization",
    },
    {
      title: 'Phone',
      key: "phone",
      dataIndex: "phone",
      render: (e) => <span>{phoneMask(e)}</span>
    },
    {
      title: 'Action',
      key: "action",
      render: (e) =>
        <Popconfirm
          placement="right"
          title={"Confirm!"}
          description={"Are you sure you want to remove this contact?"}
          onConfirm={() => removeContact(e)}
          okText="Yes"
          cancelText="No"
        >
          <Button size='small' type="primary" danger>
            Delete
          </Button>
        </Popconfirm>
    }
  ]

  const invoiceColumn = [
    {
      title: 'Due',
      key: "dueDate",
      dataIndex: "dueDate",
    },
    {
      title: 'Date',
      key: "invoiceDate",
      dataIndex: "invoiceDate",
    },
    {
      title: 'Amount',
      key: "totalPrice",
      dataIndex: "totalPrice",
    },
    {
      title: 'Status',
      key: "status",
      dataIndex: "status",
      render: (e) => e == 0 ? <span>Pending</span> : <span>Paid</span>
    }
  ]

  const removeUser = (user) => {
    setLoading(true);
    var users = userData;
    var index = users.indexOf(user);
    if (index !== -1) {
      users.splice(index, 1);
    }

    const uniqueArray = users.filter((obj, index, arr) => {
      return arr.findIndex((t) => t.email === obj.email) === index;
    });

    addUserToClient({ id: clientData.id, user: uniqueArray, type: "remove", removeUser: user })
      .then(async (response) => {
        if (response.status == "success") {
          init();
          message.success("User has been removed!");
        }
        else message.error("Something went wrong while removing a user data, please try again later!");
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
        message.error("Something went wrong while removing a user data, please try again later!");
      })
  }

  const phoneMask = (text) => {
    var x = text.replace(/\D/g, '').match(/(\d{0,3})(\d{0,3})(\d{0,4})/);
    const num = !x[2] ? x[1] : '(' + x[1] + ') ' + x[2] + (x[3] ? '-' + x[3] : '');
    return num;
  }

  const handleParse = (file) => {
    if (!file) return message.error("Enter a valid file");
    const reader = new FileReader();
    reader.onload = async ({ target }) => {
      const csv = Papa.parse(target.result, { header: false });
      const parsedData = csv?.data;

      var userData = [];
      parsedData.map((data) => {
        if (data[0] != "") userData.push({ email: data[0], first_name: "", last_name: "" });
      })

      const newUser = clientData.users.concat(userData);

      const uniqueArray = newUser.filter((obj, index, arr) => {
        return arr.findIndex((t) => t.email === obj.email) === index;
      });
      addUserToClient({ id: clientData.id, user: uniqueArray, newUser: userData })
        .then(async (response) => {
          setLoading(false);
          if (response.status == "success") {
            message.success("User data has been uploaded!");
            init();
          }
          else message.error("Something went wrong while saving user data, please try again later!");
        })
        .catch((err) => {
          console.log(err);
          setLoading(false);
          message.error("Something went wrong while saving user data, please try again later!");
        })
    };
    reader.readAsText(file);
  };

  const handleFileChange = (e) => {
    setLoading(true);
    const allowedExtensions = ["csv"];
    if (e.target.files.length) {
      const inputFile = e.target.files[0];
      const fileExtension = inputFile?.type.split("/")[1];
      if (!allowedExtensions.includes(fileExtension)) {
        alert("Please input a csv file");
        setLoading(false);
        return;
      }
      handleParse(inputFile);
    }
  };

  const addAccountUser = (values) => {
    setCreateLoading(true);

    checkUserExist(values)
      .then((response) => {
        if (response == "no-exist") {
          var user = userData;
          user.push(values);

          const uniqueArray = user.filter((obj, index, arr) => {
            return arr.findIndex((t) => t.email === obj.email) === index;
          });
          addUserToClient({ id: clientData.id, user: uniqueArray, newUser: [values] })
            .then(async (response) => {
              setCreateLoading(false);
              if (response.status == "success") message.success("User data has been added!");
              else message.error("Something went wrong while saving user data, please try again later!");
              init();
              setAddAccountModal(false);

            })
            .catch((err) => {
              console.log(err);
              setAddAccountModal(false);
              setCreateLoading(false);
              message.error("Something went wrong while saving user data, please try again later!");
            })
        } else {
          setCreateLoading(false);
          message.error("This email address is already associated")
        }
      })
      .catch((err) => {
        console.log(err);
        setCreateLoading(false);
      })

  }

  const OrganizationView = () => {
    return (
      <Row>
        <Col span={12}>
          <Row>
            <Col span={24} className="contact-line-view">
              <h4>Contact</h4>
              <Button className='edit-button' onClick={() => setEditClientModal(true)}>Edit</Button>
            </Col>
            <Col span={24} className="info-list-view">
              <span>{clientData?.organization}</span>
              <span>{clientData?.name}</span>
              <span>{clientData?.address}</span>
              <span>{clientData ? phoneMask(clientData?.phone) : ""}</span>
              <span>{clientData?.email}</span>
            </Col>

            <Col span={24} className="contact-line-view">
              <h4>Organization Domain</h4>
              <Button className='edit-button' onClick={() => setEditDomainModal(true)}>Edit</Button>
            </Col>
            <Col span={24} className="info-list-view">
              <span>{clientData?.domain}</span>
            </Col>
          </Row>
        </Col>
        <Col span={12}>
          <Row>
            <Col span={24} className="contact-line-view">
              <h4>Account Users</h4>
              <Button type="link" onClick={() => setAddAccountModal(true)}>
                (+)
              </Button>
              <input
                onChange={handleFileChange}
                className="csv-input"
                name="file"
                type="File"
              />
            </Col>
            <Col span={24}>
              <Table
                loading={loading}
                style={{ width: "600px" }}
                columns={userColumn}
                pagination={{
                  pageSize: 5
                }}
                dataSource={userData}
              />
            </Col>
          </Row>
        </Col>
      </Row>
    )
  }

  const BillingView = () => {
    return (
      <Row>
        <Col span={24} className="create-invoice-container">
          <Button type="primary" onClick={() => setCreateInvoiceModal(true)}>Create New Invoice</Button>
        </Col>
        <Col span={24}>
          <Table
            loading={loading}
            columns={invoiceColumn}
            dataSource={clientInvoice}
          />
        </Col>
      </Row>
    )
  }

  const DocumentView = () => {
    return (
      <Row>
        <Col span={24} className="create-invoice-container">
          <Button type="primary" onClick={() => setCreateContentModal(true)}>Add New Content</Button>
        </Col>
        <Col span={24}>
          <Table
            rowKey={record => record.id}
            dataSource={documentData}
            columns={documentColumn}
          />
        </Col>
      </Row>
    )
  }

  const AdminView = () => {
    return (
      <Row>
        <Col span={24} className="create-invoice-container">
          <Button type="primary" onClick={() => setCreateUserModal(true)}>Add New User</Button>
        </Col>
        <Col span={24}>
          <Table
            rowKey={record => record.id}
            dataSource={clientAdminData}
            loading={loading}
            columns={adminColumn}
          />
        </Col>
      </Row>
    )
  }

  const ContactView = () => {
    return (
      <Row>
        <Col span={24} className="create-invoice-container">
          <Button
            type="primary"
            onClick={() => setCreateContactModal(true)}
          >
            Add New Contact
          </Button>
        </Col>
        <Col span={24}>
          <Table
            rowKey={record => record.id}
            dataSource={contactList}
            loading={loading}
            columns={contactColumn}
          />
        </Col>
      </Row>
    )
  }

  const tabItem = [
    {
      label: 'Organization Details',
      children: <OrganizationView />,
      key: '1',
    },
    {
      label: 'Billing',
      children: <BillingView />,
      key: '2',
    },
    {
      label: 'Documents',
      children: <DocumentView />,
      key: '3',
    },
    {
      label: 'Admin',
      children: <AdminView />,
      key: '4',
    },
    {
      label: 'Contact',
      children: <ContactView />,
      key: '5',
    },
  ];

  const updateClient = (values) => {
    setUpdateLoading(true);
    updateClientData({ id: location.state, info: values })
      .then((response) => {
        setUpdateLoading(false);
        setEditClientModal(false);
        message.success("Client data has been updated!");
        init();
      })
      .catch((err) => {
        message.error("Something went wrong while updating domain name, please try again later!");
        setUpdateLoading(false);
      })
  }

  const updateDomain = (values) => {
    setUpdateLoading(true);
    updateClientDomain({ id: location.state, info: values })
      .then((response) => {
        setUpdateLoading(false);
        setEditDomainModal(false);
        message.success("Domain name has been updated!");
        init();
      })
      .catch((err) => {
        message.error("Something went wrong while updating domain name, please try again later!");
        setUpdateLoading(false);
      })
  }

  const addDocument = (values) => {
    if (values.type == 0) {
      if (documentFile) {
        setCreateLoading(true);
        // message.info("This may take a few minutes, so please do not close this page.")
        const documentName = new Date().getTime() + "-" + documentFile.name;

        fileUploadToStroage({ file: documentFile, name: documentName }, (snapshot) => {
          setUploadPercent(parseInt(snapshot.bytesTransferred * 100 / snapshot.totalBytes))
        })
          .then((response) => {
            setCreateLoading(false);
            setCreateContentModal(false);
            const uri = response;
            const gcs_uri = "gs://protocol-pro.appspot.com/documents/" + documentName;
            values.document = uri;
            values.created_date = (new Date()).toISOString();
            values.status = 1;
            values.client = location.state;

            convertDocumentAi({ document: gcs_uri, values: values });
            window.location.reload(false);
          })
          .catch((err) => {
            console.log(err);
            setCreateLoading(false);
            message.error("Something went wrong while uploading document, please try again later!");
          })
      } else {
        message.error("Something went wrong while uploading document, please try again later!");
      }
    } else {
      values.created_date = new Date().toISOString();
      values.status = 1;
      values.client = location.state;

      addDocumentData(values)
        .then(async (response) => {
          message.success("Video content has been created!");
          await sendContentNotification({
            text: values.notification,
            client: location.state
          })
          init();
          setCreateLoading(false);
          setCreateContentModal(false);
          window.location.reload(false);
        })
        .catch((err) => {
          console.log(err);
          setCreateLoading(false);
          message.error("Something went wrong while uploading document, please try again later!");
        })
    }
  }

  const addUser = (values) => {

    setCreateLoading(true);

    const url = process.env.NODE_ENV === 'production' ?
      'https://protocol-pro.firebaseapp.com/client-password-reset?user=' + btoa(values.email) :
      'http://localhost:3000/client-password-reset?user=' + btoa(values.email);

    sendPasswordSetEmail({ email: values.email.toLowerCase().replace(" ", ""), url: url })
      .then((response) => {
        values.client = location.state;
        addClientAdminData(values)
          .then((response) => {
            init();
            message.success("User has been created!");
            setCreateLoading(false);
            setCreateUserModal(false);
          })
          .catch((err) => {
            console.log(err);
            message.error("Something went wrong while saving a user data, please try again later!");
            setCreateLoading(false);
          })
      })
      .catch((err) => {
        console.log(err);
        setCreateLoading(false);
        message.error("Something went wrong while saving a user data, please try again later!");
      })
  }

  const updateUser = (values) => {
    setUpdateLoading(true);
    updateClientAdminData({ id: selectedAdminData.id, info: values })
      .then((response) => {
        init();
        setUpdateLoading(false);
        setEditUserModal(false);
        message.success("User data has been updated!");
      })
      .catch((err) => {
        message.error("Something went wrong while updating domain name, please try again later!");
        setUpdateLoading(false);
      })
  }

  const removeClientAdmin = (user) => {
    setLoading(true);
    removeClientAdminData({ id: user.id })
      .then((response) => {
        setLoading(false);
        if (response.status == "success") { init(); message.success("User has been removed!"); }
        else message.error("Something went wrong while removing a user data, please try again later!");
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
        message.error("Something went wrong while removing a user data, please try again later!");
      })
  }


  const addContact = (values) => {

    setCreateLoading(true);
    values.client = location.state;
    addContactData(values)
      .then((response) => {
        init();
        message.success("Contact has been created!");
        setCreateLoading(false);
        setCreateContactModal(false);
      })
      .catch((err) => {
        console.log(err);
        message.error("Something went wrong while saving a contact data, please try again later!");
        setCreateLoading(false);
      })
  }

  const removeContact = (user) => {
    setLoading(true);
    removeContactData({ id: user.id })
      .then((response) => {
        setLoading(false);
        if (response.status == "success") { init(); message.success("Contact has been removed!"); }
        else message.error("Something went wrong while removing a contact data, please try again later!");
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
        message.error("Something went wrong while removing a contact data, please try again later!");
      })
  }

  const handleNewEmailChange = (e, key) => {
    e.preventDefault();

    setEmailList(s => {
      const newArr = s.slice();
      newArr[key].value = e.target.value;
      return newArr;
    });
  }

  const handleNewItemChange = (e, field, key) => {
    e.preventDefault();

    if (field == "name") {
      setItemList(s => {
        const newArr = s.slice();
        newArr[key].name = e.target.value;
        return newArr;
      });
    } else {
      setItemList(s => {
        const newArr = s.slice();
        newArr[key].price = e.target.value;
        return newArr;
      });
    }
  }

  const validateEmail = (email) => {
    return String(email)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );
  };

  const sendInvoice = async () => {
    var email = clientData?.email;
    // emailList.map((list) => {
    //     if (validateEmail(list.value)) email.push(list.value)
    //     else { message.error("Please add correct email address!"); return; }
    // })

    var item = [];
    var totalPrice = 0;
    itemList.map((list) => {
      if (list.name == "" || list.price == "") { message.error("Please complete item list!"); return; }
      else {
        item.push({ name: list.name, value: "$" + parseFloat(list.price).toFixed(2) });
        totalPrice += parseFloat(list.price);
      }
    })

    setInvoiceLoading(true);

    var invoiceData = {
      email: email,
      item: item,
      invoiceDate: invoiceDate,
      dueDate: paymentDueDate,
      totalPrice: totalPrice,
      partner: invoicePartner,
      status: 0,
      ccUser: emailList
    }

    await sendInvoiceToCustomer(invoiceData)
      .then(async (response) => {
        if (response.data.status == "success") {
          invoiceData.client = location.state;
          invoiceData.invoice = response.data.response.id;

          try {
            await addInvoiceData(invoiceData);
            setInvoiceLoading(false);
            setCreateInvoiceModal(false);
            init();
          }
          catch (err) {
            setInvoiceLoading(false);
            console.log(err);
          }
        } else {
          setInvoiceLoading(false);
          console.log(response.data);
          message.error("Something went wrong while sending invoice to user, please try again later!");
        }
      })
      .catch((err) => {
        setInvoiceLoading(false);
        console.log(err);
        message.error("Something went wrong while sending invoice to user, please try again later!");
      })

  }

  return (
    <Sidebar>
      <Row>
        <Col span={24}>
          <h4>{clientData?.organization}</h4>
        </Col>
        <Col span={24} className="tab-container">
          {
            clientData &&
            <Tabs
              type="card"
              items={tabItem}
            />
          }
        </Col>
      </Row>

      <Modal
        title="Edit Client"
        open={editClientModal}
        onCancel={() => setEditClientModal(false)}
        footer={false}
      >
        <Row>
          <Col span={24}>
            <Form
              layout="vertical"
              wrapperCol={{ span: 24 }}
              onFinish={updateClient}
              onFinishFailed={() => message.error("Something went wrong, please try again later!")}
              autoComplete="off"
              initialValues={clientData}
            >
              <Row gutter={[12, 0]}>
                <Col span={12}>
                  <Form.Item
                    label="Organization Name"
                    name="organization"
                    rules={[{ required: true, message: 'Please input organization!' }]}
                  >
                    <Input
                      size="medium"
                      placeholder="Organization Name"
                    />
                  </Form.Item>
                </Col>

                <Col span={12}>
                  <Form.Item
                    label="Client Name"
                    name="name"
                    rules={[{ required: true, message: 'Please input name!' }]}
                  >
                    <Input
                      size="medium"
                      placeholder="Client Name"
                    />
                  </Form.Item>
                </Col>

                <Col span={12}>
                  <Form.Item
                    label="Address"
                    name="address"
                    rules={[{ required: true, message: 'Please input address!' }]}
                  >
                    <Input
                      size="medium"
                      placeholder="Address"
                    />
                  </Form.Item>
                </Col>

                <Col span={12}>
                  <Form.Item
                    label="Phone Number"
                    name="phone"
                    rules={[{ required: true, message: 'Please input phone number!' }]}
                  >
                    <Input
                      size="medium"
                      placeholder="Phone Number"
                    />
                  </Form.Item>
                </Col>

                <Col span={12}>
                  <Form.Item
                    label="Email"
                    name="email"
                    rules={[{ required: true, message: 'Please input email!' }]}
                  >
                    <Input
                      size="medium"
                      placeholder="Email"
                    />
                  </Form.Item>
                </Col>
              </Row>


              <Form.Item wrapperCol={{ span: 24 }} className="text-center">
                <Button
                  type="primary"
                  size={"medium"}
                  htmlType="submit"
                  loading={updateLoading}
                >
                  Update
                </Button>
              </Form.Item>
            </Form>
          </Col>
        </Row>
      </Modal>

      <Modal
        title="Edit Domain"
        open={editDomainModal}
        onCancel={() => setEditDomainModal(false)}
        footer={false}
      >
        <Row>
          <Col span={24}>
            <Form
              layout="vertical"
              wrapperCol={{ span: 24 }}
              onFinish={updateDomain}
              onFinishFailed={() => message.error("Something went wrong, please try again later!")}
              autoComplete="off"
              initialValues={clientData}
            >
              <Row gutter={[12, 0]}>
                <Col span={12}>
                  <Form.Item
                    label="Domain Name"
                    name="domain"
                    rules={[{ required: true, message: 'Please input domain name!' }]}
                  >
                    <Input
                      size="medium"
                      placeholder="Domain Name"
                    />
                  </Form.Item>
                </Col>
              </Row>

              <Form.Item wrapperCol={{ span: 24 }} className="text-center">
                <Button
                  type="primary"
                  size={"medium"}
                  htmlType="submit"
                  loading={updateLoading}
                >
                  Update
                </Button>
              </Form.Item>
            </Form>
          </Col>
        </Row>
      </Modal>

      <Modal
        title="Create Invoice"
        open={createInvoiceModal}
        onCancel={() => setCreateInvoiceModal(false)}
        footer={false}
        width="800px"
      >
        <Row>
          <Col span={24}>
            <Row gutter={[12, 0]}>
              <Col span={24}>
                <div className='email-title-view'>
                  <h4>Email</h4>
                  <Button
                    type='primary'
                    size='small'
                    onClick={() => {
                      setEmailList(origin => {
                        return [
                          ...origin, {
                            type: "text",
                            value: ""
                          }
                        ]
                      })
                    }}
                    className='add-new-button'
                  >
                    Add
                  </Button>
                </div>
                <span>{clientData?.email}</span>
                {
                  emailList.map((child, key) => (
                    <Row className='new-email-input' key={key}>
                      <Col span={8}>
                        <Input type={child.type} value={child.value} placeholder="Enter email address" onChange={(e) => handleNewEmailChange(e, key)} />
                      </Col>
                    </Row>
                  ))
                }
              </Col>

              <Col span={24} className="invoice-date-container">
                <span className='date-label'>Inovice Date</span>
                <DatePicker onChange={(date, dateString) => setInvoiceDate(dateString)} />
              </Col>

              <Col span={24} className="invoice-date-container">
                <span className='date-label'>Payment Due</span>
                <DatePicker onChange={(date, dateString) => setPaymentDueDate(dateString)} />
              </Col>

            </Row>
            <Row gutter={12}>
              <Col span={8}>
                <span>Item</span>
              </Col>

              <Col span={6}>
                <span>Price</span>
              </Col>
            </Row>

            {
              itemList.map((child, key) => (
                <Row gutter={12} key={key} className='new-email-input'>
                  <Col span={8}>
                    <Input
                      placeholder='Item'
                      value={child.name}
                      onChange={(e) => handleNewItemChange(e, "name", key)}
                    />
                  </Col>

                  <Col span={6}>
                    <Input
                      placeholder='Price'
                      value={child.price}
                      type="number"
                      onChange={(e) => handleNewItemChange(e, "price", key)}
                      prefix={"$"}
                    />
                  </Col>
                </Row>
              ))
            }

            <Row className='new-email-input'>
              <Col span="8">
                <Button
                  type="primary"
                  icon={<PlusOutlined />}
                  onClick={() => {
                    setItemList(origin => {
                      return [
                        ...origin, {
                          name: "",
                          price: null
                        }
                      ]
                    })
                  }}
                >
                  Add
                </Button>
              </Col>
            </Row>
            <Row>
              <Col span={24} className="partner-account-container">
                <span>Partner Account(Optional)</span><br />
                <Select
                  defaultValue="none"
                  style={{
                    width: 200,
                  }}
                  onChange={(e) => setInvoicePartner(e)}
                  options={partnerData.map((partner) => ({
                    label: partner.name,
                    value: partner.id
                  }))}
                />
              </Col>
            </Row>

            <Row>
              <Col span={24} className="invoice-send-button-container">
                <Button type="primary" icon={<SendOutlined />} onClick={() => sendInvoice()} size={"middle"} loading={invoiceLoading}>
                  Send
                </Button>
              </Col>
            </Row>
          </Col>
        </Row>
      </Modal>

      <Modal
        title="Add Content"
        open={createContentModal}
        onCancel={() => setCreateContentModal(false)}
        footer={false}
      >
        <Row>
          <Col span={24}>
            <Form
              layout="vertical"
              wrapperCol={{ span: 24 }}
              onFinish={addDocument}
              onFinishFailed={() => message.error("Something went wrong, please try again later!")}
              autoComplete="off"
            >
              <Row gutter={[12, 0]}>
                <Col span={12}>
                  <Form.Item
                    label="Content Name"
                    name="name"
                    rules={[{ required: true, message: 'Please input content name!' }]}
                  >
                    <Input
                      size="medium"
                      placeholder="Content Name"
                    />
                  </Form.Item>
                </Col>

                <Col span={12}>
                  <Form.Item
                    label="Version"
                    name="version"
                    rules={[{ required: true, message: 'Please input version!' }]}
                  >
                    <Input
                      size="medium"
                      placeholder="Version"
                      type='number'
                    />
                  </Form.Item>
                </Col>

                <Col span={12}>
                  <Form.Item
                    label="Section"
                    name="section"
                    rules={[{ required: true, message: 'Please choose section!' }]}
                  >
                    <Select
                      placeholder="Please choose section"
                      options={[
                        {
                          value: 0,
                          label: "EMS"
                        },
                        {
                          value: 1,
                          label: "Fire"
                        }
                      ]}
                    />
                  </Form.Item>
                </Col>

                <Col span={12}>
                  <Form.Item
                    label="Type"
                    name="type"
                    rules={[{ required: true, message: 'Please choose type!' }]}
                  >
                    <Select
                      placeholder="Please choose a document type"
                      options={[
                        {
                          value: 0,
                          label: "Document"
                        },
                        {
                          value: 1,
                          label: "Video"
                        }
                      ]}
                      onChange={(e) => setDocumentType(e)}
                    />
                  </Form.Item>
                </Col>

                {
                  documentType == 0 ?
                    <Col span={24}>
                      <Form.Item
                        label="Document"
                        name="document"
                        rules={[{ required: true, message: 'Please upload document!' }]}
                      >
                        <input name="document" type="file" onChange={(e) => setDocumentFile(e.target.files[0])} />
                      </Form.Item>
                    </Col>
                    :
                    <Col span={24}>
                      <Row gutter={12}>
                        <Col span={12}>
                          <Form.Item
                            label="Title"
                            name="videoTitle"
                            rules={[{ required: true, message: 'Please input video title!' }]}
                          >
                            <Input
                              size="medium"
                              placeholder="Title"
                            />
                          </Form.Item>
                        </Col>
                        <Col span={12}>
                          <Form.Item
                            label="Youtube Link"
                            name="youtube"
                            rules={[{ required: true, message: 'Please input youtube link!' }]}
                          >
                            <Input
                              size="medium"
                              placeholder="Youtube Link"
                            />
                          </Form.Item>
                        </Col>
                      </Row>
                    </Col>
                }

                <Col span={24}>
                  <Form.Item
                    label="Notification Text"
                    name="notification"
                    rules={[{ required: true, message: 'Please input notification text!' }]}
                  >
                    <TextArea
                      size="medium"
                      placeholder="Notification Text"
                    />
                  </Form.Item>
                </Col>

                {
                  createLoading &&
                  <Col span={24}>
                    <Progress percent={uploadPercent} />
                  </Col>
                }


              </Row>

              <Form.Item wrapperCol={{ span: 24 }} className="text-center">
                <Button
                  type="primary"
                  size={"medium"}
                  htmlType="submit"
                  loading={createLoading}
                >
                  Create Document
                </Button>
              </Form.Item>
            </Form>
          </Col>
        </Row>
      </Modal>

      <Modal
        title="Add User"
        open={createUserModal}
        onCancel={() => setCreateUserModal(false)}
        footer={false}
      >
        <Row>
          <Col span={24}>
            <Form
              layout="vertical"
              wrapperCol={{ span: 24 }}
              onFinish={addUser}
              onFinishFailed={() => message.error("Something went wrong, please try again later!")}
              autoComplete="off"
            >
              <Row gutter={[12, 0]}>
                <Col span={12}>
                  <Form.Item
                    label="First Name"
                    name="first_name"
                    rules={[{ required: true, message: 'Please input first name!' }]}
                  >
                    <Input
                      size="medium"
                      placeholder="First Name"
                    />
                  </Form.Item>
                </Col>

                <Col span={12}>
                  <Form.Item
                    label="Last Name"
                    name="last_name"
                    rules={[{ required: true, message: 'Please input last name!' }]}
                  >
                    <Input
                      size="medium"
                      placeholder="Last Name"
                    />
                  </Form.Item>
                </Col>

                <Col span={12}>
                  <Form.Item
                    label="Email"
                    name="email"
                    rules={[{ required: true, message: 'Please input email!' }]}
                  >
                    <Input
                      size="medium"
                      placeholder="Email"
                    />
                  </Form.Item>
                </Col>

                <Col span={12}>
                  <Form.Item
                    label="Role"
                    name="role"
                    rules={[{ required: true, message: 'Please choose a role!' }]}
                  >
                    <Select
                      placeholder="Please choose a role"
                      options={[{
                        label: "Admin",
                        value: 0
                      },
                      {
                        label: "Editor",
                        value: 1
                      }]}
                    />
                  </Form.Item>
                </Col>

              </Row>

              <Form.Item wrapperCol={{ span: 24 }} className="text-center">
                <Button
                  type="primary"
                  size={"medium"}
                  htmlType="submit"
                  loading={createLoading}
                >
                  Create User
                </Button>
              </Form.Item>
            </Form>
          </Col>
        </Row>
      </Modal>


      <Modal
        title="Edit User"
        open={editUserModal}
        onCancel={() => setEditUserModal(false)}
        footer={false}
      >
        <Row>
          <Col span={24}>
            <Form
              layout="vertical"
              wrapperCol={{ span: 24 }}
              onFinish={updateUser}
              onFinishFailed={() => message.error("Something went wrong, please try again later!")}
              autoComplete="off"
              initialValues={selectedAdminData}
            >
              <Row gutter={[12, 0]}>
                <Col span={12}>
                  <Form.Item
                    label="First Name"
                    name="first_name"
                    rules={[{ required: true, message: 'Please input first name!' }]}
                  >
                    <Input
                      size="medium"
                      placeholder="First Name"
                    />
                  </Form.Item>
                </Col>

                <Col span={12}>
                  <Form.Item
                    label="Last Name"
                    name="last_name"
                    rules={[{ required: true, message: 'Please input last name!' }]}
                  >
                    <Input
                      size="medium"
                      placeholder="Last Name"
                    />
                  </Form.Item>
                </Col>

                <Col span={12}>
                  <Form.Item
                    label="Email"
                    name="email"
                    rules={[{ required: true, message: 'Please input email!' }]}
                  >
                    <Input
                      size="medium"
                      placeholder="Email"
                    />
                  </Form.Item>
                </Col>

                <Col span={12}>
                  <Form.Item
                    label="Role"
                    name="role"
                    rules={[{ required: true, message: 'Please choose a role!' }]}
                  >
                    <Select
                      placeholder="Please choose a role"
                      options={[{
                        label: "Admin",
                        value: 0
                      },
                      {
                        label: "Editor",
                        value: 1
                      }]}
                    />
                  </Form.Item>
                </Col>

              </Row>

              <Form.Item wrapperCol={{ span: 24 }} className="text-center">
                <Button
                  type="primary"
                  size={"medium"}
                  htmlType="submit"
                  loading={updateLoading}
                >
                  Update
                </Button>
              </Form.Item>
            </Form>
          </Col>
        </Row>
      </Modal>

      <Modal
        title="Add User"
        open={createContactModal}
        onCancel={() => setCreateContactModal(false)}
        footer={false}
      >
        <Row>
          <Col span={24}>
            <Form
              layout="vertical"
              wrapperCol={{ span: 24 }}
              onFinish={addContact}
              onFinishFailed={() => message.error("Something went wrong, please try again later!")}
              autoComplete="off"
            >
              <Row gutter={[12, 0]}>
                <Col span={24}>
                  <Form.Item
                    label="Title"
                    name="title"
                    rules={[{ required: true, message: 'Please input title!' }]}
                  >
                    <Input
                      size="medium"
                      placeholder="Title"
                    />
                  </Form.Item>
                </Col>

                <Col span={24}>
                  <Form.Item
                    label="First Name"
                    name="first_name"
                    rules={[{ required: true, message: 'Please input first name!' }]}
                  >
                    <Input
                      size="medium"
                      placeholder="First Name"
                    />
                  </Form.Item>
                </Col>

                <Col span={24}>
                  <Form.Item
                    label="Last Name"
                    name="last_name"
                    rules={[{ required: true, message: 'Please input last name!' }]}
                  >
                    <Input
                      size="medium"
                      placeholder="Last Name"
                    />
                  </Form.Item>
                </Col>

                <Col span={24}>
                  <Form.Item
                    label="Organization"
                    name="organization"
                    rules={[{ required: true, message: 'Please input organization!' }]}
                  >
                    <Input
                      size="medium"
                      placeholder="Organization"
                    />
                  </Form.Item>
                </Col>

                <Col span={24}>
                  <Form.Item
                    label="Phone"
                    name="phone"
                    rules={[{ required: true, message: 'Please input phone number!' }]}
                  >
                    <Input
                      size="medium"
                      placeholder="Phone"
                    />
                  </Form.Item>
                </Col>

              </Row>

              <Form.Item wrapperCol={{ span: 24 }} className="text-center">
                <Button
                  type="primary"
                  size={"medium"}
                  htmlType="submit"
                  loading={createLoading}
                >
                  Create Contact
                </Button>
              </Form.Item>
            </Form>
          </Col>
        </Row>
      </Modal>


      <Modal
        title="Add Account"
        open={addAccountModal}
        onCancel={() => setAddAccountModal(false)}
        footer={false}
      >
        <Row>
          <Col span={24}>
            <Form
              layout="vertical"
              wrapperCol={{ span: 24 }}
              onFinish={addAccountUser}
              onFinishFailed={() => message.error("Something went wrong, please try again later!")}
              autoComplete="off"
            >
              <Row gutter={[12, 0]}>
                <Col span={12}>
                  <Form.Item
                    label="First Name"
                    name="first_name"
                    rules={[{ required: true, message: 'Please input first name!' }]}
                  >
                    <Input
                      size="medium"
                      placeholder="Name"
                    />
                  </Form.Item>
                </Col>

                <Col span={12}>
                  <Form.Item
                    label="Last Name"
                    name="last_name"
                    rules={[{ required: true, message: 'Please input last name!' }]}
                  >
                    <Input
                      size="medium"
                      placeholder="Name"
                    />
                  </Form.Item>
                </Col>

                <Col span={24}>
                  <Form.Item
                    label="Email"
                    name="email"
                    rules={[{ required: true, message: 'Please input email address!' }]}
                  >
                    <Input
                      size="medium"
                      placeholder="Name"
                    />
                  </Form.Item>
                </Col>
              </Row>

              <Form.Item wrapperCol={{ span: 24 }} className="text-center">
                <Button
                  type="primary"
                  size={"medium"}
                  htmlType="submit"
                  loading={createLoading}
                >
                  Create Account
                </Button>
              </Form.Item>
            </Form>
          </Col>
        </Row>
      </Modal>


    </Sidebar >
  );
}
