import { createBrowserRouter } from "react-router-dom";
import ClientBilling from "./admin/billing/ClientBilling";
import ClientAdminLoginScreen from "./admin/ClientAdminLoginScreen";
import ClientAdminPasswordScreen from "./admin/ClientAdminPasswordScreen";
import ClientContent from "./admin/content/ClientContent";
import ClientUser from "./admin/user/ClientUser";
import AdminPage from "./dashboard/admin/AdminPage";
import ClientDetail from "./dashboard/client/ClientDetail";
import ClientPage from "./dashboard/client/ClientPage";
import LoginScreen from "./dashboard/LoginScreen";
import PartnerDetail from "./dashboard/partner/PartnerDetail";
import PartnerPage from "./dashboard/partner/PartnerPage";
import RefreshPage from "./dashboard/stripe/RefreshPage";
import ReturnPage from "./dashboard/stripe/ReturnPage";
import PDFViewer from "./viewer/PDFViewer";
import DocumentDetail from "./dashboard/document/DocumentDetail";
import ClientAdmin from "./admin/admin/ClientAdmin";
import ClientAdminSignUpScreen from "./admin/ClientAdminSignUpScreen";
import ClientContact from "./admin/contact/ClientContact";
import MedicalDirectorPage from "./dashboard/medicalDirector/MedicalDirector";
import ClientAdminMedicalDirectorPage from "./admin/medicalDirector/ClientAdminMedicalDirector";

export const router = createBrowserRouter([
    {
        path: "/viewer",
        element: <PDFViewer />,
    },
    {
        path: "/login",
        element: <LoginScreen />,
    },
    {
        path: "/",
        element: <ClientPage />,
    },
    {
        path: "/client",
        element: <ClientPage />,
    },
    {
        path: "/partners",
        element: <PartnerPage />,
    },
    {
        path: "/partner-detail",
        element: <PartnerDetail />,
    },
    {
        path: "/client-detail",
        element: <ClientDetail />,
    },
    {
        path: "/admin",
        element: <AdminPage />,
    },
    {
        path: "/stripe/refresh",
        element: <RefreshPage />,
    },
    {
        path: "/stripe/return",
        element: <ReturnPage />,
    },
    {
        path: "/client-login",
        element: <ClientAdminLoginScreen />,
    },
    {
        path: "/client-content",
        element: <ClientContent />,
    },
    {
        path: "/client-user",
        element: <ClientUser />,
    },
    {
        path: "/client-billing",
        element: <ClientBilling />,
    },
    {
        path: "/client-password-reset",
        element: <ClientAdminPasswordScreen />,
    },
    {
        path: "/document-detail",
        element: <DocumentDetail />,
    },
    {
        path: "/client-admin",
        element: <ClientAdmin />,
    },
    {
        path: "/client-contact",
        element: <ClientContact />,
    },
    {
        path: "/signup",
        element: <ClientAdminSignUpScreen />,
    },
    {
        path: "/medical-directors",
        element: <MedicalDirectorPage />,
    },
    {
        path: "/client-admin-medical-directors",
        element: <ClientAdminMedicalDirectorPage />,
    },
]);