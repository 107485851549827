import { addDoc, collection, deleteDoc, doc, getDoc, getDocs, limit, orderBy, query, updateDoc, where } from "firebase/firestore";
import { FBDB } from "./fireStore";
import { sendNotification } from "../api/point";

export const siteLogin = async (param) => {
  const userDoc = (
    await getDocs(
      query(
        collection(FBDB, "admin"),
        where("email", "==", param.email.toLowerCase()),
        where("password", "==", param.password),
        limit(1)
      )
    )
  );
  if (userDoc.docs.length > 0) {
    return userDoc.docs[0].data();
  } else {
    return null;
  }
}

export const getClientData = async (param) => {

  return getDocs(collection(FBDB, "clients"))
    .then((querySnapshot) => {
      const newData = querySnapshot.docs
        .map((doc) => ({ ...doc.data(), id: doc.id }));
      return newData;
    })
}

export const addClientData = async (param) => {
  try {
    const docRef = await addDoc(collection(FBDB, "clients"), param);
    return docRef.id;
  }
  catch (err) {
    console.log(err);
    return null;
  }
}

export const removeClientData = async (param) => {
  try {
    const logDocs = await getDocs(
      query(collection(FBDB, "log"), where("client", "==", param.id))
    );
    logDocs.forEach(async (doc) => {
      await deleteDoc(doc.ref);
    });

    const documentDocs = await getDocs(
      query(collection(FBDB, "documents"), where("client", "==", param.id))
    );
    documentDocs.forEach(async (doc) => {
      await deleteDoc(doc.ref);
    });

    const clientAdminDocs = await getDocs(
      query(collection(FBDB, "clientAdmin"), where("client", "==", param.id))
    );
    clientAdminDocs.forEach(async (doc) => {
      await deleteDoc(doc.ref);
    });

    const contactDocs = await getDocs(
      query(collection(FBDB, "contact"), where("client", "==", param.id))
    );
    contactDocs.forEach(async (doc) => {
      await deleteDoc(doc.ref);
    });

    await deleteDoc(doc(FBDB, "clients", param.id));

    return { status: "success" };
  } catch (err) {
    console.log(err);
    return { status: "failed", err: err };
  }
};

export const getClientDataByID = async (param) => {
  try {
    const docRef = doc(FBDB, "clients", param.id);
    const docSnap = await getDoc(docRef);
    if (docSnap.exists()) {
      return { ...docSnap.data(), id: docSnap.id, ref: docSnap.ref };
    } else {
      return {};
    }
  } catch (error) {
    console.log(error);
    return {};
  }
}

export const getClientUser = async (param) => {
  try {
    const q = query(collection(FBDB, "log"), where("client", "==", param.id));
    const querySnapshot = await getDocs(q);
    const userData = querySnapshot.docs
      .map((doc) => ({ ...doc.data(), id: doc.id }));
    return userData
  } catch (error) {
    console.log(error);
    return {};
  }
}

export const checkUserExist = async (param) => {
  const userDoc = (
    await getDocs(
      query(
        collection(FBDB, "log"),
        where("email", "==", param.email.toLowerCase()),
        limit(1)
      )
    )
  );
  if (userDoc.docs.length > 0) {
    return "exist";
  } else {
    return "no-exist";
  }
}

export const addUserToClient = async (param) => {
  try {

    if (param?.type == "remove") {
      const q = query(collection(FBDB, "log"), where("email", "==", param.removeUser.email));
      const querySnapshot = await getDocs(q);
      const userData = querySnapshot.docs
        .map((doc) => ({ ...doc.data(), id: doc.id }));
      const user = userData[0];

      await deleteDoc(doc(FBDB, "log", user.id));
      return { status: "success" }
    } else {
      await Promise.all(param.newUser.map(async (item) => {
        const userDoc = (
          await getDocs(
            query(
              collection(FBDB, "log"),
              where("email", "==", item.email.toLowerCase()),
              limit(1)
            )
          )
        );
        if (userDoc.docs.length == 0) {
          const value = {
            client: param.id,
            first_name: item.first_name,
            last_name: item.last_name,
            email: item.email
          }
          const docRef = await addDoc(collection(FBDB, "log"), value);
        }
      }))
      return { status: "success" }
    }
  } catch (err) {
    console.log(err);
    return { status: "failed", err: err };
  }
}

export const addUserToLog = async (param) => {
  try {
    const docRef = await addDoc(collection(FBDB, "log"), param);
    return docRef.id;
  }
  catch (err) {
    console.log(err);
    return null;
  }
}

export const updateClientData = async (param) => {
  try {
    const ref = doc(FBDB, "clients", param.id)
    return updateDoc(ref, {
      organization: param.info.organization,
      name: param.info.name,
      email: param.info.email,
      phone: param.info.phone,
      address: param.info.address
    }).then(response => {
      return { status: "success" }
    }).catch(error => {
      return { status: "failed", err: error }
    })

  } catch (err) {
    console.log(err);
    return { status: "failed", err: err };
  }
}


export const updatePartnerData = async (param) => {
  try {
    const ref = doc(FBDB, "partners", param.id)
    return updateDoc(ref, {
      name: param.info.name,
      email: param.info.email.toLowerCase().replaceAll(" ", ""),
      phone: param.info.phone,
      address: param.info.address,
      commission: param.info.commission,
      term: param.info.term
    }).then(response => {
      return { status: "success" }
    }).catch(error => {
      return { status: "failed", err: error }
    })

  } catch (err) {
    console.log(err);
    return { status: "failed", err: err };
  }
}

export const updateClientDomain = async (param) => {
  try {
    const ref = doc(FBDB, "clients", param.id)
    return updateDoc(ref, {
      domain: param.info.domain
    }).then(response => {
      return { status: "success" }
    }).catch(error => {
      return { status: "failed", err: error }
    })

  } catch (err) {
    console.log(err);
    return { status: "failed", err: err };
  }
}


export const getAdminData = async (param) => {
  return getDocs(collection(FBDB, "admin"))
    .then((querySnapshot) => {
      const newData = querySnapshot.docs
        .map((doc) => ({ ...doc.data(), id: doc.id }));
      return newData;
    })
}


export const addAdminData = async (param) => {
  try {
    const docRef = await addDoc(collection(FBDB, "admin"), param);
    return docRef.id;
  }
  catch (err) {
    console.log(err);
    return null;
  }
}

export const updateAdminData = async (param) => {
  try {
    const ref = doc(FBDB, "admin", param.id)
    return updateDoc(ref, {
      name: param.info.name,
      email: param.info.email,
      password: param.info.password
    }).then(response => {
      return { status: "success" }
    }).catch(error => {
      return { status: "failed", err: error }
    })

  } catch (err) {
    console.log(err);
    return { status: "failed", err: err };
  }
}

export const getContactData = async (param) => {
  return getDocs(collection(FBDB, "contact"))
    .then((querySnapshot) => {
      const newData = querySnapshot.docs
        .map((doc) => ({ ...doc.data(), id: doc.id }));
      return newData;
    })
}


export const getContactByID = async (param) => {
  try {
    const q = query(collection(FBDB, "contact"), where("client", "==", param.client));
    const querySnapshot = await getDocs(q);
    const userData = querySnapshot.docs
      .map((doc) => ({ ...doc.data(), id: doc.id }));
    return userData;
  } catch (error) {
    console.log(error);
    return {};
  }
}

export const addContactData = async (param) => {
  try {
    const docRef = await addDoc(collection(FBDB, "contact"), param);
    return docRef.id;
  }
  catch (err) {
    console.log(err);
    return null;
  }
}


export const removeContactData = async (param) => {
  try {
    await deleteDoc(doc(FBDB, "contact", param.id));
    return { status: "success" }
  } catch (err) {
    console.log(err);
    return { status: "failed", err: err };
  }
}

export const addClientAdminData = async (param) => {
  try {
    const docRef = await addDoc(collection(FBDB, "clientAdmin"), param);
    return docRef.id;
  }
  catch (err) {
    console.log(err);
    return null;
  }
}

export const getClientAdminData = async () => {
  return getDocs(collection(FBDB, "clientAdmin"))
    .then((querySnapshot) => {
      const newData = querySnapshot.docs
        .map((doc) => ({ ...doc.data(), id: doc.id }));
      return newData;
    })
}

export const getClientAdminByID = async (param) => {
  try {
    const q = query(collection(FBDB, "clientAdmin"), where("client", "==", param.client));
    const querySnapshot = await getDocs(q);
    const userData = querySnapshot.docs
      .map((doc) => ({ ...doc.data(), id: doc.id }));
    return userData;
  } catch (error) {
    console.log(error);
    return {};
  }
}

export const getClientAdminByEmail = async (param) => {
  try {
    const q = query(collection(FBDB, "clientAdmin"), where("email", "==", param.email));
    const querySnapshot = await getDocs(q);
    if (!querySnapshot.empty) {
      const userData = querySnapshot.docs
        .map((doc) => ({ ...doc.data(), id: doc.id }));
      return userData[0];
    } else {
      return {};
    }
  } catch (error) {
    console.log(error);
    return {};
  }
}


export const updateClientAdminData = async (param) => {
  try {
    const ref = doc(FBDB, "clientAdmin", param.id)
    return updateDoc(ref, {
      first_name: param.info.first_name,
      last_name: param.info.last_name,
      email: param.info.email,
      role: param.info.role
    }).then(response => {
      return { status: "success" }
    }).catch(error => {
      return { status: "failed", err: error }
    })

  } catch (err) {
    console.log(err);
    return { status: "failed", err: err };
  }
}


export const removeClientAdminData = async (param) => {
  try {
    await deleteDoc(doc(FBDB, "clientAdmin", param.id));
    return { status: "success" }
  } catch (err) {
    console.log(err);
    return { status: "failed", err: err };
  }
}

export const getPartnerData = async (param) => {

  return getDocs(collection(FBDB, "partners"))
    .then((querySnapshot) => {
      const newData = querySnapshot.docs
        .map((doc) => ({ ...doc.data(), id: doc.id }));
      return newData;
    })
}


export const addPartnerData = async (param) => {
  try {
    const docRef = await addDoc(collection(FBDB, "partners"), param);
    return docRef.id;
  }
  catch (err) {
    console.log(err);
    return null;
  }
}

export const getPartnerDataById = async (param) => {
  try {
    const docRef = doc(FBDB, "partners", param.id);
    const docSnap = await getDoc(docRef);
    if (docSnap.exists()) {
      return { ...docSnap.data(), id: docSnap.id, ref: docSnap.ref };
    } else {
      return {};
    }
  } catch (error) {
    console.log(error);
    return {};
  }
}

export const addInvoiceData = async (param) => {
  try {
    const docRef = await addDoc(collection(FBDB, "invoice"), param);
    return docRef.id;
  }
  catch (err) {
    console.log(err);
    return null;
  }
}


export const getClientInvoiceData = async (param) => {
  try {
    const q = query(collection(FBDB, "invoice"), where("client", "==", param.client));
    const querySnapshot = await getDocs(q);
    const userData = querySnapshot.docs
      .map((doc) => ({ ...doc.data(), id: doc.id }));
    return userData;
  } catch (error) {
    console.log(error);
    return {};
  }
}


export const getPartnerDataByAccountId = async (param) => {
  try {
    const q = query(collection(FBDB, "partners"), where("stripe_account", "==", param.id));
    const querySnapshot = await getDocs(q);
    const userData = querySnapshot.docs
      .map((doc) => ({ ...doc.data(), id: doc.id }));
    return userData;
  } catch (error) {
    console.log(error);
    return {};
  }
}

export const updateStripeAccountLink = async (param) => {
  try {
    const ref = doc(FBDB, "partners", param.id)
    return updateDoc(ref, {
      stripe_account_link: param.link
    }).then(response => {
      return { status: "success" }
    }).catch(error => {
      return { status: "failed", err: error }
    })
  } catch (err) {
    console.log(err);
    return { status: "failed", err: err };
  }
}

export const updateStripeAccountStatus = async (param) => {
  try {
    const ref = doc(FBDB, "partners", param.id)
    return updateDoc(ref, {
      stripe_status: 1
    }).then(response => {
      return { status: "success" }
    }).catch(error => {
      return { status: "failed", err: error }
    })
  } catch (err) {
    console.log(err);
    return { status: "failed", err: err };
  }
}

export const getClientDataByAdmin = async (param) => {
  try {
    const q = query(collection(FBDB, "clientAdmin"), where("email", "==", param.email));
    const querySnapshot = await getDocs(q);
    const userData = querySnapshot.docs
      .map((doc) => ({ ...doc.data(), id: doc.id }));
    const client = userData[0].client;

    const docRef = doc(FBDB, "clients", client);
    const docSnap = await getDoc(docRef);
    if (docSnap.exists()) {
      return { ...docSnap.data(), id: docSnap.id, ref: docSnap.ref };
    } else {
      return {};
    }

  } catch (error) {
    console.log(error);
    return {};
  }
}

export const clientResetPassword = async (param) => {
  try {

    const q = query(collection(FBDB, "clientAdmin"), where("email", "==", param.email));
    const querySnapshot = await getDocs(q);
    const userData = querySnapshot.docs
      .map((doc) => ({ ...doc.data(), id: doc.id }));


    if (userData.length > 0) {
      const client = userData[0];

      const ref = doc(FBDB, "clientAdmin", client.id)
      return updateDoc(ref, {
        password: btoa(param.password)
      }).then(response => {
        return { status: "success" }
      }).catch(error => {
        return { status: "failed", err: error }
      })
    } else {
      return { status: "invalid" };
    }
  } catch (err) {
    console.log(err);
    return { status: "failed", err: err };
  }
}


export const checkClientAdminCredential = async (param) => {
  const userDoc = (
    await getDocs(
      query(
        collection(FBDB, "clientAdmin"),
        where("email", "==", param.email),
        where("password", "==", btoa(param.password)),
      )
    )
  );
  if (userDoc.docs.length > 0) {
    return userDoc.docs[0].data();
  } else {
    return null;
  }
}

export const sendContentNotification = async (param) => {
  try {
    const q = query(collection(FBDB, "log"), where("client", "==", param.client));
    const querySnapshot = await getDocs(q);
    const logData = querySnapshot.docs
      .map((doc) => ({ ...doc.data(), id: doc.id }));

    sendNotification({ logData: logData, text: param.text })
    return "success";
  } catch (error) {
    console.log(error);
    return {};
  }
}


export const checkClientExist = async (param) => {
  const userDoc = (
    await getDocs(
      query(
        collection(FBDB, "clients"),
        where("email", "==", param.email.toLowerCase()),
        limit(1)
      )
    )
  );
  if (userDoc.docs.length > 0) {
    return "exist";
  } else {
    return "no-exist";
  }
}


export const checkClientAdminExist = async (param) => {
  const userDoc = (
    await getDocs(
      query(
        collection(FBDB, "clientAdmin"),
        where("email", "==", param.email.toLowerCase()),
        limit(1)
      )
    )
  );
  if (userDoc.docs.length > 0) {
    return "exist";
  } else {
    return "no-exist";
  }
}


// medical doctors

export const getMedicalDirectorData = async () => {
  return getDocs(collection(FBDB, "medicalDirectors"))
    .then((querySnapshot) => {
      const newData = querySnapshot.docs
        .map((doc) => ({ ...doc.data(), id: doc.id }));
      return newData;
    })
}


export const addMedicalDirectorData = async (param) => {
  try {
    const docRef = await addDoc(collection(FBDB, "medicalDirectors"), param);
    return docRef.id;
  }
  catch (err) {
    console.log(err);
    return null;
  }
}

export const updateMedicalDirectorData = async (param) => {
  try {

    const ref = doc(FBDB, "medicalDirectors", param.id)
    let updateInfo = {
      name: param.info.name,
      phoneNumber: param.info.phoneNumber
    }
    if (param.info.clientAdmin != null) {
      updateInfo = { ...updateInfo, clientAdmin : param.info.clientAdmin}
    }
    return updateDoc(ref, updateInfo).then(response => {
      return { status: "success" }
    }).catch(error => {
      return { status: "failed", err: error }
    })

  } catch (err) {
    console.log(err);
    return { status: "failed", err: err };
  }
}

export const deleteMedicalDirectorData = async (param) => {
  try {

    return deleteDoc(doc(FBDB, "medicalDirectors", param.id))
    .then(_ => {
      return { status: "success" }
    }).catch(error => {
      return { status: "failed", err: error }
    })

  } catch (err) {
    console.log(err);
    return { status: "failed", err: err };
  }
}

export const getMedicalDirectorDataByClientAdminId = async (param) => {
  const q = query(collection(FBDB, "medicalDirectors"), where("clientAdmin.id", "==", param.client.id));
  return getDocs(q)
    .then((querySnapshot) => {
      const newData = querySnapshot.docs
        .map((doc) => ({ ...doc.data(), id: doc.id }));
      return newData;
    })
}
