import { Button, Col, Form, Input, Modal, Popconfirm, Row, Table, message } from 'antd';
import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { ClientSideBar } from '../../container/ClientSideBar';
import { addContactData, getContactByID, removeContactData } from '../../script/auth';

export default function ClientContact() {

  const navigate = useNavigate();
  const location = useLocation();
  const [loading, setLoading] = useState(false);
  const [userData, setUserData] = useState(null);
  const [contactList, setContactList] = useState([]);
  const [createLoading, setCreateLoading] = useState(false);
  const [createContactModal, setCreateContactModal] = useState(false);

  const [form] = Form.useForm();

  const phoneMask = (text) => {
    var x = text.replace(/\D/g, '').match(/(\d{0,3})(\d{0,3})(\d{0,4})/);
    const num = !x[2] ? x[1] : '(' + x[1] + ') ' + x[2] + (x[3] ? '-' + x[3] : '');
    return num;
  }

  const getContact = (client) => {
    setLoading(true)
    getContactByID({ client: client })
      .then((response) => {
        setContactList(response);
        setLoading(false)
      })
      .catch((err) => {
        console.log(err);
        message.error("Something went wrong while fetching client document data, please try again later!");
        setLoading(false)
      })
  }

  const init = async () => {
    const data = await localStorage.getItem("user");
    try {
      if (data) {
        const user = JSON.parse(data);
        if (user.role == 0) {
          navigate("/client-login");
        } else {
          setUserData(user.client);
          getContact(user.client.id)
        }
      }
    }
    catch (err) {
      console.log(err)
      navigate("/client-login");
    }
  }

  useEffect(() => {
    if (localStorage.getItem('user')) {
      const user = JSON.parse(localStorage.getItem("user"));
      if (user.role == 0) {
        navigate("/client-login");
      } else {
        init();
      }
    }
    else navigate("/client-login");
  }, [])


  const addContact = (values) => {
    setCreateLoading(true);
    values.client = userData.id;
    addContactData(values)
      .then((response) => {
        init();
        message.success("Contact has been created!");
        setCreateLoading(false);
        setCreateContactModal(false);
        form.resetFields();
      })
      .catch((err) => {
        console.log(err);
        message.error("Something went wrong while saving a contact data, please try again later!");
        setCreateLoading(false);
      })
  }

  const removeContact = (user) => {
    setLoading(true);
    removeContactData({ id: user.id })
      .then((response) => {
        setLoading(false);
        if (response.status == "success") { init(); message.success("Contact has been removed!"); }
        else message.error("Something went wrong while removing a contact data, please try again later!");
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
        message.error("Something went wrong while removing a contact data, please try again later!");
      })
  }

  const contactColumn = [
    {
      title: 'Title',
      key: "title",
      dataIndex: "title",
    },
    {
      title: 'Name',
      key: "first_name",
      dataIndex: "first_name",
    },
    {
      title: 'Last Name',
      key: "last_name",
      dataIndex: "last_name",
    },
    {
      title: 'Organization',
      key: "organization",
      dataIndex: "organization",
    },
    {
      title: 'Phone',
      key: "phone",
      dataIndex: "phone",
      render: (e) => <span>{phoneMask(e)}</span>
    },
    {
      title: 'Action',
      key: "action",
      render: (e) =>
        <Popconfirm
          placement="right"
          title={"Confirm!"}
          description={"Are you sure you want to remove this contact?"}
          onConfirm={() => removeContact(e)}
          okText="Yes"
          cancelText="No"
        >
          <Button size='small' type="primary" danger>
            Delete
          </Button>
        </Popconfirm>
    }
  ]

  return (
    <ClientSideBar>
      <Row>
        <Col span={24} className="create-invoice-container">
          <Button
            type="primary"
            onClick={() => setCreateContactModal(true)}
          >
            Add New Contact
          </Button>
        </Col>
        <Col span={24}>
          <Table
            rowKey={record => record.id}
            dataSource={contactList}
            loading={loading}
            columns={contactColumn}
          />
        </Col>
      </Row>


      <Modal
        title="Add User"
        open={createContactModal}
        onCancel={() => setCreateContactModal(false)}
        footer={false}
      >
        <Row>
          <Col span={24}>
            <Form
              form={form}
              layout="vertical"
              wrapperCol={{ span: 24 }}
              onFinish={addContact}
              onFinishFailed={() => message.error("Something went wrong, please try again later!")}
              autoComplete="off"
            >
              <Row gutter={[12, 0]}>
                <Col span={24}>
                  <Form.Item
                    label="Title"
                    name="title"
                    rules={[{ required: true, message: 'Please input title!' }]}
                  >
                    <Input
                      size="medium"
                      placeholder="Title"
                    />
                  </Form.Item>
                </Col>

                <Col span={24}>
                  <Form.Item
                    label="First Name"
                    name="first_name"
                    rules={[{ required: true, message: 'Please input first name!' }]}
                  >
                    <Input
                      size="medium"
                      placeholder="First Name"
                    />
                  </Form.Item>
                </Col>

                <Col span={24}>
                  <Form.Item
                    label="Last Name"
                    name="last_name"
                    rules={[{ required: true, message: 'Please input last name!' }]}
                  >
                    <Input
                      size="medium"
                      placeholder="Last Name"
                    />
                  </Form.Item>
                </Col>

                <Col span={24}>
                  <Form.Item
                    label="Organization"
                    name="organization"
                    rules={[{ required: true, message: 'Please input organization!' }]}
                  >
                    <Input
                      size="medium"
                      placeholder="Organization"
                    />
                  </Form.Item>
                </Col>

                <Col span={24}>
                  <Form.Item
                    label="Phone"
                    name="phone"
                    rules={[{ required: true, message: 'Please input phone number!' }]}
                  >
                    <Input
                      size="medium"
                      placeholder="Phone"
                    />
                  </Form.Item>
                </Col>

              </Row>

              <Form.Item wrapperCol={{ span: 24 }} className="text-center">
                <Button
                  type="primary"
                  size={"medium"}
                  htmlType="submit"
                  loading={createLoading}
                >
                  Create Contact
                </Button>
              </Form.Item>
            </Form>
          </Col>
        </Row>
      </Modal>


    </ClientSideBar>
  )
}
