import { Button, Col, Form, Input, InputNumber, message, Modal, Row, Table, Popconfirm } from 'antd';
import React, { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { ClientSideBar } from '../../container/ClientSideBar';
import { addMedicalDirectorData, updateMedicalDirectorData, deleteMedicalDirectorData, getMedicalDirectorDataByClientAdminId, getClientAdminByID, getClientAdminByEmail } from '../../script/auth';
import { PatternFormat } from 'react-number-format';

export default function ClientAdminMedicalDirectorPage() {

  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);
  const [newMedicalDirectorModal, setNewMedicalDirectorModal] = useState(false);
  const [medicalDirectorList, setMedicalDirectorList] = useState([]);
  const [selectedMedicalDirector, setSelectedMedicalDirector] = useState(null);
  const [editMedicalDirector, setEditMedicalDirector] = useState(false);
  const [form] = Form.useForm(); 
  const [userData, setUserData] = useState(null);
  const [clientAdmin, setClientAdmin] = useState(null)


  const getMedicalDirectors = (client) => {
    setLoading(true)
    getMedicalDirectorDataByClientAdminId({ client: client })
      .then((response) => {
        setMedicalDirectorList(response);
        setLoading(false)
      })
      .catch((err) => {
        console.log(err);
        message.error("Something went wrong while fetching medical directors data, please try again later!");
        setLoading(false)
      })
  }

  const getClientAdmin = (email) => {
    getClientAdminByEmail({email: email})
    .then((response) => {
        setClientAdmin(response)
        getMedicalDirectors(response)
    })
    .catch((err) => {
    })
  }

  const init = async () => {
    const data = localStorage.getItem("user");
    try {
      if (data) {
        const user = JSON.parse(data);
        if (user.role == 0) {
          navigate("/client-login");
        } else {
          setUserData(user.client);
          getClientAdmin(user.client.email)
        }
      }
    }
    catch (err) {
      console.log(err)
      navigate("/client-login");
    }
  }

  useEffect(() => {
    if (localStorage.getItem('user')) {
      const user = JSON.parse(localStorage.getItem("user"));
      if (user.role == 0) {
        navigate("/client-login");
      } else {
        init();
      }
    }
    else navigate("/client-login");
  }, [])

  const medicalDirectorColumns = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Phonenumber',
      dataIndex: 'phoneNumber',
      key: 'phoneNumber',
    },
    {
      title: 'Action',
      render: (e) =>
        <div>
          <Button size='small' type="primary" onClick={() => {
            setSelectedMedicalDirector(e);
            setEditMedicalDirector(true);
          }}>
            Edit
          </Button>
          <Popconfirm
            placement="right"
            title={"Confirm!"}
            description={"Are you sure you want to remove this medical director?"}
            onConfirm={() => deleteMedicalData(e)}
            okText="Yes"
            cancelText="No"
          >
            <Button
              type="primary"
              size="small"
              danger
              style={{ marginLeft: 2 }}
            >
              Delete
            </Button>
          </Popconfirm>
        </div>
    }
  ];

  const createNewDoctor = (values) => {
    setLoading(true);
    const newMedicalDirectorInfo = {
      ...values, "clientAdmin": clientAdmin
    }
    addMedicalDirectorData(newMedicalDirectorInfo)
      .then((response) => {
        setLoading(false);
        init();
        setNewMedicalDirectorModal(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
        message.error("Something went wrong while saving medical director data. Please try again later!");
      })
  }

  const updateMedicalData = (values) => {
    setLoading(true);
    updateMedicalDirectorData({ id: selectedMedicalDirector.id, info: values })
      .then((response) => {
        setLoading(false);
        setEditMedicalDirector(false);
        message.success("Medical director data has been updated!");
        init();
      })
      .catch((err) => {
        message.error("Something went wrong while updating Medical director data, please try again later!");
        setLoading(false);
      })
  }

  const deleteMedicalData = (values) => {
    setLoading(true);
    deleteMedicalDirectorData(values)
    .then((response) => {
        setLoading(false);
        message.success(`Medical director ${values.name} is deleted!`);
        init();
      })
      .catch((err) => {
        message.error("Something went wrong while deleting Medical director data, please try again later!");
        setLoading(false);
      })
  }

  return (
    <ClientSideBar>
      <Row className='top-container'>
        <Col span={12}>
          <h3 className='page-title'>Medical Directors</h3>
        </Col>
        <Col span={12} className="top-add-button-container">
          <Button onClick={() => setNewMedicalDirectorModal(true)}>Add</Button>
        </Col>
      </Row>
      <Row>
        <Col span={24} className="">
          <Table
            loading={loading}
            dataSource={medicalDirectorList}
            columns={medicalDirectorColumns}
          />
        </Col>
      </Row>


      <Modal
        title="Add New Medical Director"
        open={newMedicalDirectorModal}
        onCancel={() => setNewMedicalDirectorModal(false)}
        footer={false}
      >
        <Row>
          <Col span={24}>
            <Form
              layout="vertical"
              wrapperCol={{ span: 24 }}
              onFinish={createNewDoctor}
              onFinishFailed={() => message.error("Something went wrong, please try again later!")}
              autoComplete="off"
            >
              <Row gutter={[12, 0]}>
                <Col span={24}>
                  <Form.Item
                    label="Medical Director Name"
                    name="name"
                    rules={[{ required: true, message: 'Please input name!' }]}
                  >
                    <Input
                      size="medium"
                      placeholder="Medical Director Name"
                    />
                  </Form.Item>
                </Col>

                <Col span={24}>
                  <Form.Item
                    label="Phonenumber"
                    name="phoneNumber"
                    rules={[{ required: true, message: 'Please input phonenumber!' }]}
                  >
                    <PatternFormat format='(###) ### - ####' allowEmptyFormatting 
                    mask="_" size="medium"
                    />
                  </Form.Item>
                </Col>
              </Row>


              <Form.Item wrapperCol={{ span: 24 }} className="text-center">
                <Button
                  type="primary"
                  size={"medium"}
                  htmlType="submit"
                  loading={loading}
                >
                  Create
                </Button>
              </Form.Item>
            </Form>
          </Col>
        </Row>
      </Modal>

      <Modal
        title="Edit Medical Director"
        form={form}
        open={editMedicalDirector}
        onCancel={() => setEditMedicalDirector(false)}
        footer={false}
      >
        <Row>
          <Col span={24}>
            <Form
              layout="vertical"
              initialValues={selectedMedicalDirector}
              wrapperCol={{ span: 24 }}
              onFinish={updateMedicalData}
              onFinishFailed={() => message.error("Something went wrong, please try again later!")}
              autoComplete="off"
            >
              <Row gutter={[12, 0]}>
                <Col span={24}>
                  <Form.Item
                    label="Medical Director Name"
                    name="name"
                    rules={[{ required: true, message: 'Please input name!' }]}
                  >
                    <Input
                      size="medium"
                      placeholder="Medical Director Name"
                    />
                  </Form.Item>
                </Col>

                <Col span={24}>
                  <Form.Item
                    label="Phonenumber"
                    name="phoneNumber"
                    rules={[{ required: true, message: 'Please input phonenumber!' }]}
                  >
                    <PatternFormat format='(###) ### - ####' allowEmptyFormatting 
                    mask="_" size="medium"
                    />
                  </Form.Item>
                </Col>
              </Row>


              <Form.Item wrapperCol={{ span: 24 }} className="text-center">
                <Button
                  type="primary"
                  size={"medium"}
                  htmlType="submit"
                  loading={loading}
                >
                  Update
                </Button>
              </Form.Item>
            </Form>
          </Col>
        </Row>
      </Modal>

    </ClientSideBar>
  );
}
