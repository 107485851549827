import { ProgressBar, Viewer, Worker } from '@react-pdf-viewer/core';
import '@react-pdf-viewer/core/lib/styles/index.css';
import '@react-pdf-viewer/default-layout/lib/styles/index.css';
import { Trigger, highlightPlugin } from '@react-pdf-viewer/highlight';
import { pageNavigationPlugin } from '@react-pdf-viewer/page-navigation';
import '@react-pdf-viewer/page-navigation/lib/styles/index.css';
import '@react-pdf-viewer/zoom/lib/styles/index.css';
import React, { useEffect, useRef, useState } from 'react';
import { make2dTransformValue } from "react-quick-pinch-zoom";
import { useSearchParams } from 'react-router-dom';
import './style.css';


function PDFViewer() {

  const [searchParams, setSearchParams] = useSearchParams();
  const [pdfLink, setPdfLink] = useState(null);
  const [base64Data, setBase64Data] = useState("");
  const [pageNumber, setPageNumber] = useState(0);

  const pdfType = searchParams.get("pdf_type")
  const device = searchParams.get("device")

  const init = () => {
    const link = searchParams.get("pdf_url")
    const page = searchParams.get("page")
    if (link) {
      setPdfLink(link);
    }
    if (page) {
      setPageNumber(parseInt(page) - 1);
    }
  }

  useEffect(() => {
    init();
  }, [])

  const base64toBlob = (data) => {
    // Cut the prefix `data:application/pdf;base64` from the raw base 64
    const base64WithoutPrefix = data.substr('data:application/pdf;base64,'.length);
    const bytes = atob(base64WithoutPrefix);
    let length = bytes.length;
    let out = new Uint8Array(length);
    while (length--) {
      out[length] = bytes.charCodeAt(length);
    }
    return new Blob([out], { type: 'application/pdf' });
  };

  const pageNavigationPluginInstance = pageNavigationPlugin();
  const [highLightArea, setHighLightArea] = useState([]);
  const [highLightIndex, setHighLightIndex] = useState(null);

  const handleDocumentLoad = async (e) => {
    const bookmarkData = await e.doc.getOutline();
    var pairs = [];
    if (bookmarkData) {
      await Promise.all(bookmarkData.map(async (bookmarkItem) => {
        const ref = bookmarkItem.dest[0];
        try {
          await e.doc.getPageIndex(ref).then(function (id) {
            console.log(bookmarkItem.title);
            pairs.push({
              title: bookmarkItem.title,
              page: parseInt(id),
            });
          });
        }
        catch (err) {
          console.log("error", err);
        }
      }))
    }
    if (document.ReactNativeWebView) {
      if (pairs) {
        document.ReactNativeWebView.postMessage(JSON.stringify(pairs));
      }
    }
    if (window.ReactNativeWebView) {
      if (pairs) {
        window.ReactNativeWebView.postMessage(JSON.stringify(pairs));
      }
    }
  };

  const renderHighlights = (props) => (
    <div>
      {highLightArea
        .filter((area) => area.pageIndex === props.pageIndex)
        .map((area, idx) => (
          <div
            key={idx}
            className="highlight-area"
            style={Object.assign(
              {},
              {
                background: area?.key == highLightArea[highLightIndex]?.key ? "#2ba3ff" : 'yellow',
                opacity: 0.4,
              },
              props.getCssProperties(area, props.rotation)
            )}
          />
        ))}
    </div>
  )

  const highlightPluginInstance = highlightPlugin({
    renderHighlights,
    trigger: Trigger.None,
  });

  const { jumpToPage } = pageNavigationPluginInstance;

  document.addEventListener('message', event => {
    try {
      const param = JSON.parse(event.data);
      if (param.type == "jumpPage") {
        jumpToPage(param.data)
        setHighLightIndex(param.index)
      }
      if (param.type == "highlight") {
        setHighLightArea(param.data)
      }
      if (param.type == "base64pdf") {
        if (param.data == "document_load_finished") {
          const blob = base64toBlob(`data:application/pdf;base64,${base64Data}`);
          const url = URL.createObjectURL(blob);
          setPdfLink(url);
        } else {
          var pdfData = base64Data;
          pdfData += param.data;
          setBase64Data(pdfData);
        }
      }
    }
    catch (err) {
    }
  });

  window.addEventListener('message', event => {
    try {
      const param = JSON.parse(event.data);
      if (param.type == "jumpPage") {
        setHighLightIndex(param.index)
        jumpToPage(param.data)
      }
      if (param.type == "highlight") {
        setHighLightArea(param.data)
      }
      if (param.type == "base64pdf") {
        const blob = base64toBlob(param.data);
        const url = URL.createObjectURL(blob);
        setPdfLink(url);
      }
    }
    catch (err) {
    }
  });

  const innerRef = useRef(null);

  return (
    <div ref={innerRef} className='pdf-viewer'>
      {
        pdfLink &&
        <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.4.120/build/pdf.worker.min.js">
          <Viewer
            fileUrl={pdfLink}
            plugins={[
              pageNavigationPluginInstance,
              highlightPluginInstance
            ]}
            // defaultScale={0.6}
            initialPage={pageNumber}
            defaultScale={device == "tablet" ? 1.2 : 0.6}
            onDocumentLoad={handleDocumentLoad}
            className="pdf-viewer-data"
            renderLoader={(percentages) => {
              return (
                <div style={{ width: '240px' }}>
                  <ProgressBar progress={Math.round(percentages)} />
                </div>
              )
            }}
          />
        </Worker>
      }
    </div>
  );
}
export default PDFViewer;
