import { DollarCircleOutlined, FilePdfOutlined, LogoutOutlined, UserOutlined } from '@ant-design/icons';
import { Button, Col, Layout, Menu, Row, theme } from 'antd';
import { useLocation, useNavigate } from 'react-router-dom';

export function ClientSideBar({ children }) {

  const navigate = useNavigate();
  const location = useLocation();

  const { Header, Content, Footer, Sider } = Layout;

  function getItem(label, key, icon, children) {
    return {
      key,
      icon,
      children,
      label
    };
  }

  const items = [
    getItem('Content', '/client-content', <FilePdfOutlined />),
    getItem('Users', '/client-user', <UserOutlined />),
    getItem('Billing', '/client-billing', <DollarCircleOutlined />),
    getItem('Admin', '/client-admin', <UserOutlined />),
    getItem('Contact', '/client-contact', <UserOutlined />),
    getItem('Medical Directors', '/client-admin-medical-directors', <UserOutlined />)
  ];

  const {
    token: { colorBgContainer },
  } = theme.useToken();

  return (
    <Layout
      style={{
        minHeight: '100vh',
        maxHeight: '100vh',
        overflow: "auto"
      }}
    >
      <Sider>
        <div style={{ paddingTop: 50 }}>
          <Menu theme="dark" defaultSelectedKeys={[location.pathname]} mode="inline" items={items} onClick={(e) => navigate(e.key)} />
        </div>
      </Sider>
      <Layout className="site-layout">

        <Header
          style={{
            background: colorBgContainer,
          }}
          className='header-container'
        >
          <Row className='header-container'>
            <Col span={12} className='logo-container'>
              <img src={require("../assets/image/full-logo.png")} className="header-logo" />
            </Col>
            <Col span={12} style={{ textAlign: "right" }}>
              <Button
                icon={<LogoutOutlined />}
                onClick={() => {
                  localStorage.removeItem("user");
                  navigate("/client-login")
                }}
              >
                Log Out
              </Button>
            </Col>
          </Row>
        </Header>

        <Content
          style={{
            margin: '20px 16px',
          }}
        >
          {children}
        </Content>
      </Layout>
    </Layout>
  )
}
