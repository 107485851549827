import { getDownloadURL, getStorage, listAll, ref, uploadBytesResumable } from "firebase/storage";
import { FBApp } from "./fireStore";
const storage = getStorage(FBApp);

export const fileUploadToStroage = async (param, progressCallback) => {

  const storageRef = ref(storage, `/documents/${param.name}`);
  const uploadTask = uploadBytesResumable(storageRef, param.file);

  return new Promise((resolve, reject) => {
    uploadTask.on(
      "state_changed",
      (snapshot) => {
        if (progressCallback) {
          progressCallback(snapshot);
        }
      },
      (err) => reject(err),
      async () => {
        try {
          const url = await getDownloadURL(uploadTask.snapshot.ref);
          resolve(url)
        } catch (err) {
          reject(err);
        }
      }
    );
  });
}

export const getSearchFile = async (param) => {
  const prefix = param.prefix.replace("gs://protocol-pro.appspot.com/", "");
  const prefixRef = ref(storage, prefix);

  try {
    const result = await listAll(prefixRef);
    return result.items;
  }
  catch (err) {
    return err;
  }
}
