import { Button, Col, Form, Input, message, Modal, Popconfirm, Row, Select, Table } from 'antd';
import React, { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Sidebar } from '../../container/Sidebar';
import { addAdminData, addClientAdminData, getAdminData, getClientAdminByID, getClientDataByID, removeClientAdminData, updateAdminData, updateClientAdminData, updateClientData, updateClientDomain } from '../../script/auth';
import { ClientSideBar } from '../../container/ClientSideBar';
import { sendPasswordSetEmail } from '../../api/point';

export default function ClientAdmin() {

  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);
  const [newAdminModal, setNewAdminModal] = useState(false);
  const [clientData, setClientData] = useState(null);
  const [adminList, setAdminList] = useState([]);
  const [editDomainModal, setEditDomainModal] = useState(false);
  const [editClientModal, setEditClientModal] = useState(false);
  const [form] = Form.useForm();
  const [updateLoading, setUpdateLoading] = useState(false);
  const [selectedAdminData, setSelectedAdminData] = useState(null);
  const [editUserModal, setEditUserModal] = useState(false);
  const [createUserModal, setCreateUserModal] = useState(false);
  const [createLoading, setCreateLoading] = useState(false);

  const init = () => {
    const user = JSON.parse(localStorage.getItem("user"));
    setLoading(true);
    getClientDataByID({ id: user.client.id })
      .then((response) => {
        setClientData(response);
        getClientAdminByID({ client: user.client.id })
          .then((response) => {
            setAdminList(response);
            setLoading(false);
          })
          .catch((err) => {
            console.log(err);
            message.error("Something went wrong while fetch client data. Please try again later!");
            setLoading(false);
          })
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
        message.error("Something went wrong while fetching client data, please try again later!");
      })
  }

  const phoneMask = (text) => {
    var x = text.replace(/\D/g, '').match(/(\d{0,3})(\d{0,3})(\d{0,4})/);
    const num = !x[2] ? x[1] : '(' + x[1] + ') ' + x[2] + (x[3] ? '-' + x[3] : '');
    return num;
  }


  useEffect(() => {
    if (localStorage.getItem('user')) {
      const user = JSON.parse(localStorage.getItem("user"));
      if (user.role == 0) {
        navigate("/client-login");
      } else {
        init();
      }
    }
    else navigate("/client-login");
  }, [])


  const adminColumn = [
    {
      title: 'First Name',
      key: "first_name",
      dataIndex: "first_name",
    },
    {
      title: 'Last Name',
      key: "last_name",
      dataIndex: "last_name",
    },
    {
      title: 'Email',
      key: "email",
      dataIndex: "email",
    },
    {
      title: 'Role',
      key: "role",
      dataIndex: "role",
      render: (e) => e == 0 ? <span>Admin</span> : <span>Editor</span>
    },
    {
      title: 'Action',
      key: "action",
      render: (e) =>
        <>
          <Button size='small' type="primary" onClick={() => { setSelectedAdminData(e); setEditUserModal(true) }}>Edit</Button>
          <Popconfirm
            placement="right"
            title={"Confirm!"}
            description={"Are you sure you want to remove this user?"}
            onConfirm={() => removeClientAdmin(e)}
            okText="Yes"
            cancelText="No"
          >
            <Button size='small' type="primary" danger>
              Delete
            </Button>
          </Popconfirm>
        </>
    }
  ]

  const addUser = (values) => {

    setCreateLoading(true);

    const url = process.env.NODE_ENV === 'production' ?
      'https://protocol-pro.firebaseapp.com/client-password-reset?user=' + btoa(values.email) :
      'http://localhost:3000/client-password-reset?user=' + btoa(values.email);

    sendPasswordSetEmail({ email: values.email.toLowerCase().replace(" ", ""), url: url })
      .then((response) => {
        values.client = clientData.id;
        addClientAdminData(values)
          .then((response) => {
            init();
            message.success("User has been created!");
            setCreateLoading(false);
            setCreateUserModal(false);
          })
          .catch((err) => {
            console.log(err);
            message.error("Something went wrong while saving a user data, please try again later!");
            setCreateLoading(false);
          })
      })
      .catch((err) => {
        console.log(err);
        setCreateLoading(false);
        message.error("Something went wrong while saving a user data, please try again later!");
      })
  }

  const updateUser = (values) => {
    setUpdateLoading(true);
    updateClientAdminData({ id: selectedAdminData.id, info: values })
      .then((response) => {
        init();
        setUpdateLoading(false);
        setEditUserModal(false);
        message.success("User data has been updated!");
      })
      .catch((err) => {
        message.error("Something went wrong while updating domain name, please try again later!");
        setUpdateLoading(false);
      })
  }


  const removeClientAdmin = (user) => {
    setLoading(true);
    removeClientAdminData({ id: user.id })
      .then((response) => {
        setLoading(false);
        if (response.status == "success") { init(); message.success("User has been removed!"); }
        else message.error("Something went wrong while removing a user data, please try again later!");
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
        message.error("Something went wrong while removing a user data, please try again later!");
      })
  }

  const updateClient = (values) => {
    setUpdateLoading(true);
    updateClientData({ id: clientData.id, info: values })
      .then((response) => {
        setUpdateLoading(false);
        setEditClientModal(false);
        message.success("Client data has been updated!");
        init();
      })
      .catch((err) => {
        message.error("Something went wrong while updating domain name, please try again later!");
        setUpdateLoading(false);
      })
  }

  const updateDomain = (values) => {
    setUpdateLoading(true);
    updateClientDomain({ id: clientData.id, info: values })
      .then((response) => {
        setUpdateLoading(false);
        setEditDomainModal(false);
        message.success("Domain name has been updated!");
        init();
      })
      .catch((err) => {
        message.error("Something went wrong while updating domain name, please try again later!");
        setUpdateLoading(false);
      })
  }

  return (
    <ClientSideBar>
      <Row>
        <Col span={24}>
          <Row>
            <Col span={24} className="contact-line-view">
              <h4>Contact</h4>
              <Button className='edit-button' onClick={() => setEditClientModal(true)}>Edit</Button>
            </Col>
            <Col span={24} className="info-list-view">
              <span>{clientData?.organization}</span>
              <span>{clientData?.name}</span>
              <span>{clientData?.address}</span>
              <span>{clientData ? phoneMask(clientData?.phone) : ""}</span>
              <span>{clientData?.email}</span>
            </Col>

            <Col span={24} className="contact-line-view">
              <h4>Organization Domain</h4>
              <Button className='edit-button' onClick={() => setEditDomainModal(true)}>Edit</Button>
            </Col>
            <Col span={24} className="info-list-view">
              <span>{clientData?.domain}</span>
            </Col>
          </Row>
        </Col>
      </Row>



      <Row className='top-container' style={{ marginTop: 30 }}>
        <Col span={12}>
        </Col>
        <Col span={12} className="top-add-button-container">
          <Button onClick={() => setCreateUserModal(true)}>Add New User</Button>
        </Col>
      </Row>


      <Row>
        <Col span={24} className="">
          <Table
            loading={loading}
            dataSource={adminList}
            columns={adminColumn}
          />
        </Col>
      </Row>




      <Modal
        title="Edit Client"
        open={editClientModal}
        onCancel={() => setEditClientModal(false)}
        footer={false}
      >
        <Row>
          <Col span={24}>
            <Form
              layout="vertical"
              wrapperCol={{ span: 24 }}
              onFinish={updateClient}
              onFinishFailed={() => message.error("Something went wrong, please try again later!")}
              autoComplete="off"
              initialValues={clientData}
            >
              <Row gutter={[12, 0]}>
                <Col span={12}>
                  <Form.Item
                    label="Organization Name"
                    name="organization"
                    rules={[{ required: true, message: 'Please input organization!' }]}
                  >
                    <Input
                      size="medium"
                      placeholder="Organization Name"
                    />
                  </Form.Item>
                </Col>

                <Col span={12}>
                  <Form.Item
                    label="Client Name"
                    name="name"
                    rules={[{ required: true, message: 'Please input name!' }]}
                  >
                    <Input
                      size="medium"
                      placeholder="Client Name"
                    />
                  </Form.Item>
                </Col>

                <Col span={12}>
                  <Form.Item
                    label="Address"
                    name="address"
                    rules={[{ required: true, message: 'Please input address!' }]}
                  >
                    <Input
                      size="medium"
                      placeholder="Address"
                    />
                  </Form.Item>
                </Col>

                <Col span={12}>
                  <Form.Item
                    label="Phone Number"
                    name="phone"
                    rules={[{ required: true, message: 'Please input phone number!' }]}
                  >
                    <Input
                      size="medium"
                      placeholder="Phone Number"
                    />
                  </Form.Item>
                </Col>

                <Col span={12}>
                  <Form.Item
                    label="Email"
                    name="email"
                    rules={[{ required: true, message: 'Please input email!' }]}
                  >
                    <Input
                      size="medium"
                      placeholder="Email"
                    />
                  </Form.Item>
                </Col>
              </Row>


              <Form.Item wrapperCol={{ span: 24 }} className="text-center">
                <Button
                  type="primary"
                  size={"medium"}
                  htmlType="submit"
                  loading={updateLoading}
                >
                  Update
                </Button>
              </Form.Item>
            </Form>
          </Col>
        </Row>
      </Modal>

      <Modal
        title="Edit Domain"
        open={editDomainModal}
        onCancel={() => setEditDomainModal(false)}
        footer={false}
      >
        <Row>
          <Col span={24}>
            <Form
              layout="vertical"
              wrapperCol={{ span: 24 }}
              onFinish={updateDomain}
              onFinishFailed={() => message.error("Something went wrong, please try again later!")}
              autoComplete="off"
              initialValues={clientData}
            >
              <Row gutter={[12, 0]}>
                <Col span={12}>
                  <Form.Item
                    label="Domain Name"
                    name="domain"
                    rules={[{ required: true, message: 'Please input domain name!' }]}
                  >
                    <Input
                      size="medium"
                      placeholder="Domain Name"
                    />
                  </Form.Item>
                </Col>
              </Row>

              <Form.Item wrapperCol={{ span: 24 }} className="text-center">
                <Button
                  type="primary"
                  size={"medium"}
                  htmlType="submit"
                  loading={updateLoading}
                >
                  Update
                </Button>
              </Form.Item>
            </Form>
          </Col>
        </Row>
      </Modal>

      <Modal
        title="Add User"
        open={createUserModal}
        onCancel={() => setCreateUserModal(false)}
        footer={false}
      >
        <Row>
          <Col span={24}>
            <Form
              layout="vertical"
              wrapperCol={{ span: 24 }}
              onFinish={addUser}
              onFinishFailed={() => message.error("Something went wrong, please try again later!")}
              autoComplete="off"
            >
              <Row gutter={[12, 0]}>
                <Col span={12}>
                  <Form.Item
                    label="First Name"
                    name="first_name"
                    rules={[{ required: true, message: 'Please input first name!' }]}
                  >
                    <Input
                      size="medium"
                      placeholder="First Name"
                    />
                  </Form.Item>
                </Col>

                <Col span={12}>
                  <Form.Item
                    label="Last Name"
                    name="last_name"
                    rules={[{ required: true, message: 'Please input last name!' }]}
                  >
                    <Input
                      size="medium"
                      placeholder="Last Name"
                    />
                  </Form.Item>
                </Col>

                <Col span={12}>
                  <Form.Item
                    label="Email"
                    name="email"
                    rules={[{ required: true, message: 'Please input email!' }]}
                  >
                    <Input
                      size="medium"
                      placeholder="Email"
                    />
                  </Form.Item>
                </Col>

                <Col span={12}>
                  <Form.Item
                    label="Role"
                    name="role"
                    rules={[{ required: true, message: 'Please choose a role!' }]}
                  >
                    <Select
                      placeholder="Please choose a role"
                      options={[{
                        label: "Admin",
                        value: 0
                      },
                      {
                        label: "Editor",
                        value: 1
                      }]}
                    />
                  </Form.Item>
                </Col>

              </Row>

              <Form.Item wrapperCol={{ span: 24 }} className="text-center">
                <Button
                  type="primary"
                  size={"medium"}
                  htmlType="submit"
                  loading={createLoading}
                >
                  Create User
                </Button>
              </Form.Item>
            </Form>
          </Col>
        </Row>
      </Modal>


      <Modal
        title="Edit User"
        open={editUserModal}
        onCancel={() => setEditUserModal(false)}
        footer={false}
      >
        <Row>
          <Col span={24}>
            <Form
              layout="vertical"
              wrapperCol={{ span: 24 }}
              onFinish={updateUser}
              onFinishFailed={() => message.error("Something went wrong, please try again later!")}
              autoComplete="off"
              initialValues={selectedAdminData}
            >
              <Row gutter={[12, 0]}>
                <Col span={12}>
                  <Form.Item
                    label="First Name"
                    name="first_name"
                    rules={[{ required: true, message: 'Please input first name!' }]}
                  >
                    <Input
                      size="medium"
                      placeholder="First Name"
                    />
                  </Form.Item>
                </Col>

                <Col span={12}>
                  <Form.Item
                    label="Last Name"
                    name="last_name"
                    rules={[{ required: true, message: 'Please input last name!' }]}
                  >
                    <Input
                      size="medium"
                      placeholder="Last Name"
                    />
                  </Form.Item>
                </Col>

                <Col span={12}>
                  <Form.Item
                    label="Email"
                    name="email"
                    rules={[{ required: true, message: 'Please input email!' }]}
                  >
                    <Input
                      size="medium"
                      placeholder="Email"
                    />
                  </Form.Item>
                </Col>

                <Col span={12}>
                  <Form.Item
                    label="Role"
                    name="role"
                    rules={[{ required: true, message: 'Please choose a role!' }]}
                  >
                    <Select
                      placeholder="Please choose a role"
                      options={[{
                        label: "Admin",
                        value: 0
                      },
                      {
                        label: "Editor",
                        value: 1
                      }]}
                    />
                  </Form.Item>
                </Col>

              </Row>

              <Form.Item wrapperCol={{ span: 24 }} className="text-center">
                <Button
                  type="primary"
                  size={"medium"}
                  htmlType="submit"
                  loading={updateLoading}
                >
                  Update
                </Button>
              </Form.Item>
            </Form>
          </Col>
        </Row>
      </Modal>

    </ClientSideBar>
  );
}
