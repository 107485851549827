import { ExportOutlined, LockOutlined } from '@ant-design/icons';
import { Button, Card, Col, Form, Input, message, Row } from 'antd';
import { React, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { clientResetPassword } from '../script/auth';
import "./style.css";

export default function ClientAdminPasswordScreen() {

  const [searchParams, setSearchParams] = useSearchParams();
  const user = atob(searchParams.get("user"));

  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();

  const handelSetPassword = (values) => {
    setLoading(true);
    if (values.password.length >= 5) {
      clientResetPassword({ email: user, password: values.password })
        .then((response) => {
          if (response.status == "success") {
            setLoading(false);
            navigate("/client-login");
          } else {
            if (response.status == "invalid") {
              message.error("You are not registered client admin!");
            } else {
              setLoading(false);
              message.error("Something went wrong, please try again later!");
            }
          }
        })
        .catch((err) => {
          console.log(err);
          setLoading(false);
          message.error("Something went wrong, please try again later!");
        })
    } else {
      setLoading(false);
      message.error("Your password has to be longer than 5 characters!");
    }

  }

  return (
    <Row align={"middle"}>
      <Col xs={{ span: 22, offset: 1 }} md={{ span: 12, offset: 6 }}>
        <Card style={{ marginTop: "20%" }}>
          <Row>
            <Col span={24} className="text-center">
              <img src={require("../assets/image/logo.png")} className="logo" />
            </Col>

            <Col span={24} style={{ marginTop: 50 }}>
              <Form
                layout="vertical"
                wrapperCol={{ span: 24 }}
                initialValues={{ remember: true }}
                onFinish={handelSetPassword}
                autoComplete="off"
              >
                <Form.Item
                  label="Password"
                  name="password"
                  rules={[{ required: true, message: 'Please input your password!' }]}
                >
                  <Input
                    type='password'
                    size="large"
                    placeholder="Password"
                    prefix={<LockOutlined />}
                  />
                </Form.Item>
                <Form.Item wrapperCol={{ span: 24 }} className="text-center">
                  <Button
                    type="primary"
                    icon={<ExportOutlined />}
                    size={"large"}
                    htmlType="submit"
                    loading={loading}
                  >
                    Set Password
                  </Button>
                </Form.Item>
              </Form>
            </Col>
          </Row>
        </Card>
      </Col>
    </Row>
  );
};
