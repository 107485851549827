import { Button, Col, Form, Input, InputNumber, message, Modal, Row, Table, Popconfirm, Select } from 'antd';
import React, { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Sidebar } from '../../container/Sidebar';
import { addMedicalDirectorData, getMedicalDirectorData, updateMedicalDirectorData, deleteMedicalDirectorData, getClientAdminData } from '../../script/auth';
import { PatternFormat } from 'react-number-format';

export default function MedicalDirectorPage() {

  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);
  const [newMedicalDirectorModal, setNewMedicalDirectorModal] = useState(false);
  const [medicalDirectorList, setMedicalDirectorList] = useState([]);
  const [selectedMedicalDirector, setSelectedMedicalDirector] = useState(null);
  const [editMedicalDirector, setEditMedicalDirector] = useState(false);
  const [form] = Form.useForm();

  const [searchText, setSearchText] = useState('');
  const [clientAdmins, setClientAdmins] = useState([]);

  const filteredClientAdmins = clientAdmins.filter(clientAdmin =>
    clientAdmin.first_name.toLowerCase().includes(searchText.toLowerCase()) ||
    clientAdmin.last_name.toLowerCase().includes(searchText.toLowerCase())  ||
    clientAdmin.email.toLowerCase().includes(searchText.toLowerCase()) 
  );

  const handleSearch = (value) => {
    setSearchText(value);
  };

  const { Option } = Select;


  const init = () => {
    setLoading(true);
    getMedicalDirectorData()
      .then((response) => {
        setMedicalDirectorList(
          response.map(client => {
            client.clientAdmin = client.clientAdmin.email
            return client
          })
        );
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        message.error("Something went wrong while medical doctors data. Please try again later!");
        setLoading(false);
      })
      getClientAdminData()
      .then((response) => {
        setClientAdmins(response);
      })
  }

  useEffect(() => {
    if (localStorage.getItem('user')) {
      const user = JSON.parse(localStorage.getItem("user"));
      if (user.role == 1) {
        navigate("/login");
      } else {
        init();
      }
    }
    else navigate("/login");
  }, [])


  const medicalDirectorColumns = [
    {
      title: 'ClientEmail',
      dataIndex: 'clientAdmin',
      key: 'clientAdmin',
    },
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Phonenumber',
      dataIndex: 'phoneNumber',
      key: 'phoneNumber',
    },
    {
      title: 'Action',
      render: (e) =>
        <div>
          <Button size='small' type="primary" onClick={() => {
            setSelectedMedicalDirector(e);
            setEditMedicalDirector(true);
          }}>
            Edit
          </Button>
          <Popconfirm
            placement="right"
            title={"Confirm!"}
            description={"Are you sure you want to remove this medical director?"}
            onConfirm={() => deleteMedicalData(e)}
            okText="Yes"
            cancelText="No"
          >
            <Button
              type="primary"
              size="small"
              danger
              style={{ marginLeft: 2 }}
            >
              Delete
            </Button>
          </Popconfirm>
        </div>
    }
  ];

  const createNewDoctor = (values) => {
    setLoading(true);
    const clientAdmin = clientAdmins.filter(clientAdmin => 
      values.clientAdmin == clientAdmin.email
    );
    const newMedicalDirectorInfo = {
      ...values, "clientAdmin": clientAdmin[0]
    }
    addMedicalDirectorData(newMedicalDirectorInfo)
      .then((response) => {
        setLoading(false);
        init();
        setNewMedicalDirectorModal(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
        message.error("Something went wrong while saving medical director data. Please try again later!");
      })
  }

  const updateMedicalData = (values) => {
    setLoading(true);
    const clientAdmin = clientAdmins.filter(admin => 
      admin.email == values.clientAdmin
    )
    if (clientAdmin.size == 0) {
      return
    }
    const updateInfo = {
      ...values, "clientAdmin": clientAdmin[0]
    }
    updateMedicalDirectorData({ id: selectedMedicalDirector.id, info: updateInfo })
      .then((response) => {
        setLoading(false);
        setEditMedicalDirector(false);
        message.success("Medical director data has been updated!");
        init();
      })
      .catch((err) => {
        message.error("Something went wrong while updating Medical director data, please try again later!");
        setLoading(false);
      })
  }

  const deleteMedicalData = (values) => {
    setLoading(true);
    deleteMedicalDirectorData(values)
    .then((response) => {
        setLoading(false);
        message.success(`Medical director ${values.name} is deleted!`);
        init();
      })
      .catch((err) => {
        message.error("Something went wrong while deleting Medical director data, please try again later!");
        setLoading(false);
      })
  }

  return (
    <Sidebar>
      <Row className='top-container'>
        <Col span={12}>
          <h3 className='page-title'>Medical Directors</h3>
        </Col>
        <Col span={12} className="top-add-button-container">
          <Button onClick={() => setNewMedicalDirectorModal(true)}>Add</Button>
        </Col>
      </Row>
      <Row>
        <Col span={24} className="">
          <Table
            loading={loading}
            dataSource={medicalDirectorList}
            columns={medicalDirectorColumns}
          />
        </Col>
      </Row>


      <Modal
        title="Add New Medical Director"
        open={newMedicalDirectorModal}
        onCancel={() => setNewMedicalDirectorModal(false)}
        footer={false}
      >
        <Row>
          <Col span={24}>
            <Form
              layout="vertical"
              wrapperCol={{ span: 24 }}
              onFinish={createNewDoctor}
              onFinishFailed={() => message.error("Something went wrong, please try again later!")}
              autoComplete="off"
            >
              <Row gutter={[12, 0]}>
                <Col span={24}>
                  <Form.Item name="clientAdmin" label="Select a client admin">
                    <Select
                      showSearch
                      placeholder="Search and select a client admin"
                      onSearch={handleSearch}
                      filterOption={false}
                      style={{ width: '100%' }}
                    >
                      {filteredClientAdmins.map(option => (
                        <Option key={option.email} value={option.email}>
                          {option.email}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
    
                <Col span={24}>
                  <Form.Item
                    label="Medical Director Name"
                    name="name"
                    rules={[{ required: true, message: 'Please input name!' }]}
                  >
                    <Input
                      size="medium"
                      placeholder="Medical Director Name"
                    />
                  </Form.Item>
                </Col>

                <Col span={24}>
                  <Form.Item
                    label="Phonenumber"
                    name="phoneNumber"
                    rules={[{ required: true, message: 'Please input phonenumber!' }]}
                  >
                    <PatternFormat format='(###) ### - ####' allowEmptyFormatting 
                    mask="_" size="medium"
                    />
                  </Form.Item>
                </Col>
              </Row>


              <Form.Item wrapperCol={{ span: 24 }} className="text-center">
                <Button
                  type="primary"
                  size={"medium"}
                  htmlType="submit"
                  loading={loading}
                >
                  Create
                </Button>
              </Form.Item>
            </Form>
          </Col>
        </Row>
      </Modal>

      <Modal
        title="Edit Medical Director"
        form={form}
        open={editMedicalDirector}
        onCancel={() => setEditMedicalDirector(false)}
        footer={false}
      >
        <Row>
          <Col span={24}>
            <Form
              layout="vertical"
              initialValues={selectedMedicalDirector}
              wrapperCol={{ span: 24 }}
              onFinish={updateMedicalData}
              onFinishFailed={() => message.error("Something went wrong, please try again later!")}
              autoComplete="off"
            >
              <Row gutter={[12, 0]}>
                <Col span={24}>
                  <Form.Item 
                    name="clientAdmin" 
                    label="Select a client admin"
                    rules={[{ required: true, message: 'Please select client admin!' }]}
                  >
                    <Select
                      showSearch
                      placeholder="Search and select a client admin"
                      onSearch={handleSearch}
                      filterOption={false}
                      style={{ width: '100%' }}
                    >
                      {filteredClientAdmins.map(option => (
                        <Option key={option.email} value={option.email}>
                          {option.email}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>

                <Col span={24}>
                  <Form.Item
                    label="Medical Director Name"
                    name="name"
                    rules={[{ required: true, message: 'Please input name!' }]}
                  >
                    <Input
                      size="medium"
                      placeholder="Medical Director Name"
                    />
                  </Form.Item>
                </Col>

                <Col span={24}>
                  <Form.Item
                    label="Phonenumber"
                    name="phoneNumber"
                    rules={[{ required: true, message: 'Please input phonenumber!' }]}
                  >
                    <PatternFormat format='(###) ### - ####' allowEmptyFormatting 
                    mask="_" size="medium"
                    />
                  </Form.Item>
                </Col>
              </Row>


              <Form.Item wrapperCol={{ span: 24 }} className="text-center">
                <Button
                  type="primary"
                  size={"medium"}
                  htmlType="submit"
                  loading={loading}
                >
                  Update
                </Button>
              </Form.Item>
            </Form>
          </Col>
        </Row>
      </Modal>

    </Sidebar>
  );
}
